<template>
  <div class="card">
    <div class="card-body">
    <div v-if="showAddNote" class="new-note-form">

      <Mentionable
          :keys="['@']"
          :items="$store.state.users.mentionableUsers"
          offset="6"
        >
        <b-textarea v-model="noteInput" placeholder="Add a New Note" :rows="2" :max-rows="3" :disabled="savingInProcess" class="form-control" />
        <template #no-result>
          {{ $store.state.users.loadingMentionableUsers ? 'Loading...' : 'No result' }}
        </template>
      </Mentionable>

      <LoadingIndicator v-if="savingInProcess" />
      <button class="btn btn-primary ml-1" v-else @click="saveNote">
        <i class="fas fa-paper-plane"></i>
      </button>
    </div>
    <LoadingIndicator v-if="status === 'loading'" centered />
    <div v-if="status === 'done'" ref="notes">
      <div class="d-flex justify-content-between">
        <div>
          <button class="btn btn-secondary btn-sm mb-2" v-if="hasDeleted" @click="showDeleted = !showDeleted">
            {{ showDeleted ? 'Hide deleted' : 'Show deleted' }}
          </button>
        </div>
        <b-pagination v-if="notesTotal > perPage" size="sm" class="text--secondary" :total-rows="notesTotal" @input="changePage" v-model="currentPage" :per-page="perPage" />
      </div>
      <table v-if="notes.length > 0" class="table">
        <thead>
          <tr>
            <th>State</th>
            <th>Date</th>
            <th>Note</th>
            <th>User</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <NotesListRow v-for="note in orderedNotes" :key="note.id" :note="note" :read-only="readOnly" @noteDeleted="fetch" />
        </tbody>
      </table>
      <div v-else class="alert alert-info mb-0">No notes have been added yet.</div>
    </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import NotesListRow from './NotesListRow.vue';
import { Mentionable } from 'vue-mention'
import 'floating-vue/dist/style.css'

export default {
  props: {
    leadlikeId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fromLead: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingIndicator,
    NotesListRow,
    Mentionable
  },
  computed: {
    notes(){
      return this.$store.state.leads.leadNotes[this.leadlikeId] || [];
    },
    orderedNotes() {
      return this.visibleNotes.slice().sort((a, b) => b.id - a.id);
    },
    visibleNotes() {
      return this.notes.filter(note => this.showDeleted || !note.deletedAt);
    },
    hasDeleted() {
      return this.notes.some(note => note.deletedAt);
    },
    showAddNote() {
      if (this.readOnly == true || this.fromLead == true) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      showDeleted: false,
      noteInput: '',
      status: 'initial',
      savingInProcess: false,
      currentPage: 1,
      notesTotal: 0,
      perPage: 100,
    };
  },
  methods: {
    async saveNote() {
      this.savingInProcess = true;
      const response = await ApiClient.leads.notes.post({
        leadId: this.leadlikeId,
        body: { note: this.noteInput },
      });
      this.savingInProcess = false;
      if (response.status === 200) {
        //const newNote = response.data.data;
        //this.notes.push(newNote);
        this.noteInput = '';

        this.currentPage = 1;
        this.fetch();
      }
    },
    
    async fetch() {
      this.status = 'loading';
      const response = await ApiClient.leads.notes.get({ leadId: this.leadlikeId, params: { page: this.currentPage, perPage: this.perPage } });
      this.status = 'done';
      //this.notes = response.data.data;
      this.$store.commit('leads/setLeadNotes', { leadId: this.leadlikeId, notes: response.data.data });
      this.notesTotal = response.data.total;
    },
    async changePage() {
      await this.fetch();
      this.$nextTick(() => {
        this.$refs.notes.scrollIntoView({ block: 'start', scrollBehavior: 'smooth' });
      });
    },
  },
  created() {
    this.fetch();
    if (!this.$store.state.users.loadingMentionableUsers && !this.$store.state.users.mentionableUsers.length) {
      this.$store.dispatch('users/fetchMentionableUsers');
    }
  },
  beforeDestroy() {
    this.$store.commit('leads/unsetLeadNotes', { leadId: this.leadlikeId });
  },
  watch: {
    leadlikeId: function () {
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
.new-note-form {
  display: flex;
  height: 60px;
  align-items: center;
  margin-bottom: 24px;
}

</style>

<style lang="scss">
.mention-item {
  padding: 10px 10px;
  border-radius: 4px;
  min-width:100px;
}

.mention-selected {
  background: #efefef;
}

.mentionable {
  width:100%;
}
</style>