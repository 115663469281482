<template>
  <div>
    <div class="card mb-4">
      <h5 class="card-header">Attachments</h5>
      <div class="card-body">
        <div class="attachments-list">
          <LoadingIndicator v-if="loading" centered />
          <div v-if="!loading">
            <div v-if="attachments.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Filename</th>
                    <th>Size</th>
                    <th>User</th>
                    <th width="200">Created at</th>
                    <th width="100"> Actions</th>
                  </tr>
                </thead>
                <Attachment v-for="item in attachments" :key="'ind' + item.id"
                            :attachment="item"
                            :isSelected="selectedAttachment && selectedAttachment.id === item.id"
                            @click="selectAttachment(item)"
                            @delete="(att) => deleteAttachment(att)"
                            @download="(att) => fetchAttachment(att)"
                            />
              </table>
            </div>
            <div v-else class="alert alert-info">No attachments have been added yet.</div>
          </div>
          <h5 class="mt-5 mb-3">Add New Attachment</h5>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form class="mt-4" @submit.stop.prevent="handleSubmit(onSubmit)">
                <div class="d-flex" style="gap:24px;">
                  <div style="width:40%">
                    <validation-provider name="Name" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="example-input-group-1" label="Name" label-for="example-input-1">
                        <b-form-input
                          id="example-input-1"
                          name="example-input-1"
                          v-model="attachment.name"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div style="width:100%">
                    <validation-provider name="Food" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="example-input-group-2" label="File" label-for="example-input-2">
                        <b-file
                          id="example-input-2"
                          name="example-input-2"
                          v-model="attachment.file"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-2-live-feedback"
                        />
                        <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </div>
              <LoadingIndicator v-if="saving" /><b-btn variant="primary" :disabled="saving" type="submit">{{ attachment.id ? 'Update' : 'Create' }} attachment</b-btn>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import Attachment from '@/components/pages/carriers/Attachment.vue';

export default {
  name: 'CarrierAttachments',
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    LoadingIndicator,
    Attachment
  },
  data() {
    return {
      attachments: [],
      attachment: {
        id: null,
        name: null,
        file: null,
      },
      selectedAttachment: null, 
      loading: false,
      saving: false,
      deleting: false,
    };
  },
  methods: {
    selectAttachment(attachment) {
      if (this.selectedAttachment && this.selectedAttachment.id === attachment.id) {
        this.selectedAttachment = null;
      } else {
        this.selectedAttachment = attachment;
      }
    },
    onSubmit() {
      this.saveAttachment();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async saveAttachment() {
      this.saving = true;

      const data = new FormData();
      data.append('file', this.attachment.file, this.attachment.file.name);
      if (this.attachment.name) data.append('name', this.attachment.name);

      const response = await ApiClient.carriers.attachments.post({
        carrierId: this.carrier.id,
        body: data,
      });
      this.saving = false;
      if (response.status === 200) {
        const newAttachment = response.data.data;
        this.attachments.push(newAttachment);
        this.attachment = {
          id: null,
          name: null,
          file: null,
        };

        this.$refs.observer.reset();
      }
    },
    async deleteAttachment(attachment) {
      this.deleting = true;
      await ApiClient.carriers.attachments.delete({
        carrierId: this.carrier.id,
        id: attachment.id,
      });
      this.deleting = false;
      this.attachments = this.attachments.filter(x => x.id != attachment.id);
    },
    async fetchAttachment(attachment) {
      let response = await ApiClient.carriers.attachments.get({
        carrierId: this.carrier.id,
        id: attachment.id,
        blobRespone: true,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.filename);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
    },
    async showAttachment(attachment) {
      const link = document.createElement('a');
      link.href = attachment.publicUrl;
      link.setAttribute('target', '_blank');
      link.click();
    },
  },
  watch: {
    carrier: {
      immediate: true,
      async handler(carrier) {
        this.loading = true;
        const response = await ApiClient.carriers.attachments.get({ carrierId: carrier.id });
        this.loading = false;
        this.attachments = response.data.data;
      },
    },
  },
};
</script>

