<template>
  <div>
    <div>
      <div v-if="!balanceFields.carrierId" class="form-group">
        <label for="carrierSearch">Carrier</label>
        <div class="d-flex align-items-center">
          <input class="form-control" id="carrierSearch" v-model="searchTerm" placeholder="Search for carrier..." autocomplete="off" />
          <button class="btn btn-outline-secondary ml-3" v-if="selectedCarrierName" @click="reset">Edit</button>
          <LoadingIndicator v-if="searchStatus === 'searching'" centered />
        </div>
        <div v-if="searchStatus === 'done'" class="position-absolute">
          <div v-if="carriers.length && selectedCarrierName == null" class=" list-group card">
            <div class="list-group-item list-group-item--clickable" v-for="carrier in carriers" :key="carrier.id">
              <div @click="closeCarriers(carrier)"  :class="className(carrier)">{{ carrierOption(carrier) }}</div>
            </div>
          </div>
          <div v-else>
            <span v-if="!selectedCarrierName"> No carriers match "{{ searchTerm }}"</span>
          </div>
        </div>
      </div>
      <div>
        <ValidatedInput
          type="textarea"
          label="Dispatch Instructions"
          id="shipperNotes"
          :value="balanceFields.dispatchInformation"
          @input="newValue => handleFieldChange('dispatchInformation', newValue)"
        />
        <div class="grid--two-column">
          <ValidatedInput
            rules="required"
            type="date"
            label="Pickup date"
            id="pickupDate"
            :value="balanceFields.pickupDate"
            @input="newValue => handleFieldChange('pickupDate', newValue)"
          />
          <ValidatedSelect
            rules="required"
            label="Pickup date type"
            id="pickupDateType"
            :value="balanceFields.pickupDateTypeId"
            :options="dateTypes"
            @input="newValue => handleFieldChange('pickupDateTypeId', newValue)"
          />
          <ValidatedInput
            rules="required"
            type="date"
            label="Delivery date"
            id="deliveryDate"
            :value="balanceFields.deliveryDate"
            @input="newValue => handleFieldChange('deliveryDate', newValue)"
          />
          <ValidatedSelect
            rules="required"
            label="Delivery date type"
            id="deliveryDateType"
            :value="balanceFields.deliveryDateTypeId"
            :options="dateTypes"
            @input="newValue => handleFieldChange('deliveryDateTypeId', newValue)"
          />
        </div>
        <div v-if="showPaymentInputs">
          <ValidatedSelect
            rules="required"
            label="Terms Begin"
            id="balanceTermStart"
            :value="balanceFields.balanceTermStartId"
            :options="balanceTermStartOptions"
            @input="newValue => handleFieldChange('balanceTermStartId', newValue)"
            v-if="tokenCan('orders:dispatch')"
          />
          <ValidatedSelect
            rules="required"
            label="Payment Terms"
            id="balanceTerm"
            :value="balanceFields.balanceTermId"
            :options="balanceTerms"
            @input="newValue => handleFieldChange('balanceTermId', newValue)"
            v-if="tokenCan('orders:dispatch')"
          />
          <ValidatedSelect
            rules="required"
            label="Payment Method"
            id="balancePaymentMethod"
            :value="balanceFields.balancePaymentMethodId"
            :options="balancePaymentMethods"
            @input="newValue => handleFieldChange('balancePaymentMethodId', newValue)"
            v-if="tokenCan('orders:dispatch')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from './LoadingIndicator.vue';
import { debounce } from 'debounce';
import ValidatedSelect from '@/components/shared/ValidatedSelect';
import ValidatedInput from '@/components/shared/ValidatedInput';
import permissionsMixin from '/src/mixins/permissions';
import balanceTermStartOptionsMixin from '@/mixins/balanceTermStartOptions';
import balanceTermsMixin from '@/mixins/balanceTerms';
import balancePaymentMethodsMixin from '@/mixins/balancePaymentMethods';
import dateTypesMixin from '@/mixins/dateTypes';

const searchCarriers = debounce(async (searchTerm, callback) => {
  callback(await ApiClient.carriers.get({ params: { search: searchTerm } }));
}, 200);

export default {
  name: 'CarrierSelector',
  data() {
    return { carriers: [], searchTerm: '', selectedCarrier: null, searchStatus: 'initial', cid: null };
  },
  props: {
    preselected: {
      type: Number,
      default: null,
    },
    offer: {
      type: Object,
      default: () => { },
    },
    value: {
      type: Number,
      default: null,
    },
    balanceFields: {
      type: Object,
      default: () => ({}),
    },
    suggestions: {
      type: Number,
      default: 5,
    },
  },
  components: {
    LoadingIndicator,
    ValidatedSelect,
    ValidatedInput,
  },
  mixins: [permissionsMixin, balanceTermStartOptionsMixin, balanceTermsMixin, balancePaymentMethodsMixin, dateTypesMixin],
  computed: {
    orderId() {
      return this.leadQuoteOrder.id;
    },
    selectedCarrierName() {
      return this.selectedCarrier?.contact?.company;
    },
    showPaymentInputs() {
      if (this.balanceFields.balanceMethod?.id != 3) {
        let totalPrice = this.balanceFields.vehicles?.map(v => v.price).reduce((total, p) => total + (parseFloat(p) || 0), 0) || 0;
        let totalDeposit = this.balanceFields.vehicles?.map(v => v.deposit).reduce((total, p) => total + (parseFloat(p) || 0), 0) || 0;
        if (this.balanceFields.carrierPay == totalPrice - totalDeposit) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    reset() {
      this.$emit('input', null);
      this.searchTerm = '';
      this.searchStatus = 'initial';
      this.cid = null;
      document.getElementById('carrierSearch').removeAttribute('disabled');
    },
    handleFieldChange(field, value) {
      Object.assign(this.balanceFields, {}, { [field]: value });
    },
    closeCarriers(carrier) {
      if(!carrier.isActive || !carrier.canDispatch) return false;
      document.getElementById('carrierSearch').setAttribute('disabled', true);
      this.searchTerm = carrier.contact.company;
      this.$emit('input', carrier.id);
      this.carriers = [];
      this.cid = carrier.id;
    },
    carrierOption(carrier) {
      return carrier?.contact.company + ' - ' + carrier?.location.city + ', ' + carrier?.location.state;
    },
    handleOffer() {
      if (this.offer) {
        this.closeCarriers(this.offer.carrierId, this.offer.carrier.contact.company);
        this.balanceFields.pickupDate = this.offer.pickupDate;
        this.balanceFields.deliveryDate = this.offer.deliveryDate;
      }
    },
    className(carrier) {
      return (!carrier.isActive || !carrier.canDispatch) ? 'text-muted' : '';
    },
  },
  mounted() {
    this.handleOffer();
  },
  watch: {
    async cid(id) {
      if (id) {
        const response = await ApiClient.carriers.get({ id });
        this.selectedCarrier = response.data.data;
      } else {
        this.selectedCarrier = null;
      }
    },
    async searchTerm(searchTerm) {
      const minimalSearchTermLength = 2;
      if (searchTerm.length > minimalSearchTermLength) {
        this.searchStatus = 'searching';
        searchCarriers(searchTerm, carriersResponse => {
          this.carriers = carriersResponse.data.data.slice(0, this.suggestions);
          this.searchStatus = 'done';
        });
      } else {
        this.searchStatus = 'initial';
        this.carriers = [];
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.list-group-item--clickable {
  cursor: pointer;
}

.list-group-item--clickable:hover {
  background-color: var(--light);
}

.selected-value {
  padding-right: 16px;
}

.grid--two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 0px;
}
</style>
