<template>
  <div>

    <ul class="nav nav-tabs mb-4">
      <li class="nav-item"><b-link :to="{ name: 'MarketRates'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRates' }">Market Rates</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'ActiveRoutes'}" :class="{ 'nav-link': true, active: this.$route.name === 'ActiveRoutes' }">Active routes</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'MarketRatesMap'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRatesMap' }">Location map</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'Calculator'}" :class="{ 'nav-link': true, active: this.$route.name === 'Calculator' }">Calculator</b-link></li>
    </ul>

    <h2>Pickup locations</h2>
    <GmapMap
      ref="pickupMapRef" :zoom="4" :center="{ lat: 41.850033, lng: -96.6500523 }" map-type-id="roadmap" style="width: 100%; height: 800px" :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
      }"
    >
      <HeatMap v-if="pickupItems.length" :data="pickupData" :options="{}" />
    </GmapMap>

    <h2 class="mt-3">Delivery locations</h2>
    <GmapMap
      ref="deliveryMapRef" :zoom="4" :center="{ lat: 41.850033, lng: -96.6500523 }" map-type-id="roadmap" style="width: 100%; height: 800px" :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
      }"
    >
      <HeatMap v-if="deliveryItems.length" :data="deliveryData" :options="{}" />
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi, MapElementFactory } from 'vue2-google-maps';
import { ApiClient } from '@/api/ApiClient';

const HeatMap =  MapElementFactory({
	name: 'heatmap',
	ctr: () => gmapApi().maps.visualization.HeatmapLayer,
	events: [],
	mappedProps: {
      options: {
        type: Object,
        twoWay: false,
        default: () => {},
      },
      data: {
        type: Array,
        twoWay: true		
      },
    },
});

export default {
  name: 'MarketRatesMap',
  components: {
    HeatMap,
  },
  data() {
    return {
      loading: false,
      pickupItems: [],
      deliveryItems: [],
    };
  },
  computed: {
      google: gmapApi,
      
      pickupData() {
          return this.google && this.pickupItems.map((marker) => {
              return {
                  location: new this.google.maps.LatLng( { lat : marker.lat, lng : marker.lng }), 
                  weight: marker.weight,
              }
          });
      },
      deliveryData() {
          return this.google && this.deliveryItems.map((marker) => {
              // the Heatmap API is one of the few that *must* use a LatLng object
              return {
                  location: new this.google.maps.LatLng( { lat : marker.lat, lng : marker.lng }), 
                  weight: marker.weight,
              }
          });
      }
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await ApiClient.marketRates.heatMap();
      this.pickupItems = response.data.data.pickup;
      this.deliveryItems = response.data.data.delivery;
      this.loading = false;
    },
  },
  
  mounted() {
    this.fetch();
    /*
      this.$refs.mapRef.$mapPromise.then(() => {
          const bounds = new this.google.maps.LatLngBounds();
          this.markers.forEach(marker => {
              bounds.extend(marker.location);
          });
          this.$refs.mapRef.fitBounds(bounds);
      });
    */
  },
};
</script>
