<template>
  <div class="outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1">
      <div :class="modalClasses">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="$emit('close')" class="close" type="button">×</button>
          </div>
          <slot />
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
export default {
  name: 'ModalDialog',
  props: {
    msize: {
      type: String,
      default: 'modal-xl',
    },
  },
  computed: {
    modalClasses() {
      return 'modal-dialog modal-dialog-centered ' + this.msize;
    },
  },
};
</script>

<style lang="scss" scoped>
.outter-modal {
  position: absolute;
  z-index: 1089;
}
</style>
