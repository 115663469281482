<template>
  <div>
    <h5>Leads Report</h5>

    <b-card class="mb-4">
      <b-form inline @submit.prevent="submit" class="d-flex justify-content-start align-items-center">

        <div class="d-flex justify-content-start align-items-center mr-5">
          <b-form-radio v-model="periodType" name="periodType" value="list">Time period</b-form-radio>
          <b-select class="custom-select ml-3" v-if="periodType == 'list'" v-model="timePeriod" :options="timeOptions" />
          <span class="ml-3">OR</span>
          <b-form-radio v-model="periodType" name="periodType" value="custom" class="ml-3">Custom date range</b-form-radio>
          <flat-pickr class="form-control ml-3" v-if="periodType != 'list'" v-model="selectedDates" :config="fltConfig" />
        </div>

        <div class="d-flex align-items-center mr-3">
          <label class="text-nowrap mr-1">Pickup State</label>
          <multiselect
            v-model="reportPickupState"
            track-by="value"
            label="label"
            placeholder="Type to search state"
            :options="statesDropdown"
            :allow-empty="true"
            :multiple="true"
            :searchable="true"
            :show-labels="false"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
          >
            <span slot="noResult">None selected</span>
          </multiselect>
        </div>

        <div class="d-flex align-items-center mr-3">
          <label class="text-nowrap mr-1">Delivery State</label>
          <multiselect
            v-model="reportDeliveryState"
            track-by="value"
            label="label"
            placeholder="Type to search state"
            :options="statesDropdown"
            :allow-empty="true"
            :multiple="true"
            :searchable="true"
            :show-labels="false"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
          >
            <span slot="noResult">None selected</span>
          </multiselect>
        </div>


        <button class="btn btn-primary ml-3">Show Report</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loading" centered />

    <b-card class="mb-4" v-if="!loading && sourcesItems.length">
      <h5>Lead sources report</h5>
      <b-table striped hover :items="sourcesItems" :fields="sourcesFields">
        <template #cell(p0)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p0List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p1)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p1List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p2)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p2List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p3)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p3List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p4)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p4List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(freshBookedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.item.freshBookedCount, row.item.freshCount) }}</span>
        </template>
        <template #cell(freshCount)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.freshList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(leadsCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
        </template>
        <template #cell(quotesCount)="row">
          <b-link :to="{ name: 'QuoteList', query: { leadList: row.item.quotesList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(ordersCount)="row">
          <b-link :to="{ name: 'OrderList', query: { leadList: row.item.ordersList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(canceledCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(dispatchedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(ratio)="row">
          {{ p(row.item.dispatchedCount, row.item.totalCount) }}
        </template>
        <template #cell(brokerFee)="row">
          {{ row.item.dispatchedCount > 0 ? Math.round(row.item.gross / row.item.dispatchedCount) : 0 }}
        </template>

        <template #cell(unclaimed)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.unclaimedList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>

        <template #cell(show_details)="row">
          <b-btn size="xs" variant="secondary" @click.prevent="toggleDetails(row)">
            {{ row.detailsShowing ? 'Hide' : 'Zoom' }}
          </b-btn>
        </template>

        <template #row-details="row">
          <b-card>
            <LoadingIndicator v-if="row.item.loading" centered />
            <b-table v-if="row.item.rows.length" striped hover :items="row.item.rows" :fields="detailsFields" :tbody-tr-class="rowClass" class="mb-0">
              <template #cell(id)="data">
                <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
              </template>
              <template #cell(priority)="data">
                {{ priorityName(data.value) }}
              </template>
            </b-table>
          </b-card>
        </template>

        <template #custom-foot="data">
          <b-tr>
            <b-th v-for="field in data.fields" :key="field.key">{{ field.key == 'name' ? 'Totals' : sourcesTotals[field.key] }}</b-th>
          </b-tr>
        </template>
      </b-table>
    </b-card>

    <b-card class="mb-4" v-if="!loading && referralsItems.length">
      <h5>Referrals report</h5>
      <b-table striped hover :items="referralsItems" :fields="referralsFields">
        <template #cell(p0)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p0List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p1)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p1List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p2)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p2List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p3)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p3List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p4)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p4List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(freshBookedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.item.freshBookedCount, row.item.freshCount) }}</span>
        </template>
        <template #cell(freshCount)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.freshList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(leadsCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
        </template>
        <template #cell(quotesCount)="row">
          <b-link :to="{ name: 'QuoteList', query: { leadList: row.item.quotesList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(ordersCount)="row">
          <b-link :to="{ name: 'OrderList', query: { leadList: row.item.ordersList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(canceledCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(dispatchedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(ratio)="row">
          {{ p(row.item.dispatchedCount, row.item.totalCount) }}
        </template>
        <template #cell(brokerFee)="row">
          {{ row.item.dispatchedCount > 0 ? Math.round(row.item.gross / row.item.dispatchedCount) : 0 }}
        </template>

        <template #cell(unclaimed)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.unclaimedList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>

        <template #cell(show_details)="row">
          <b-btn size="xs" variant="secondary" @click.prevent="toggleReferralDetails(row)">
            {{ row.detailsShowing ? 'Hide' : 'Zoom' }}
          </b-btn>
        </template>

        <template #row-details="row">
          <b-card>
            <LoadingIndicator v-if="row.item.loading" centered />
            <b-table v-if="row.item.rows.length" striped hover :items="row.item.rows" :fields="detailsFields" :tbody-tr-class="rowClass" class="mb-0">
              <template #cell(id)="data">
                <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
              </template>
              <template #cell(priority)="data">
                {{ priorityName(data.value) }}
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>
    </b-card>

    <b-card class="mb-4" v-if="!loading && agentsItems.length">
      <h5>Agent productivity report</h5>
      <b-table striped hover :items="agentsItems" :fields="agentsFields">
        <template #cell(p0)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p0List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p1)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p1List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p2)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p2List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p3)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p3List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p4)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p4List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(freshBookedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.item.freshBookedCount, row.item.freshCount) }}</span>
        </template>
        <template #cell(freshCount)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.freshList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(leadsCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
        </template>
        <template #cell(quotesCount)="row">
          <b-link :to="{ name: 'QuoteList', query: { leadList: row.item.quotesList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(ordersCount)="row">
          <b-link :to="{ name: 'OrderList', query: { leadList: row.item.ordersList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(canceledCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(dispatchedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(competitionCount)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.competitionList } }" target="_blank">
            {{ row.value }}
          </b-link>
        </template>
        <template #cell(ratio)="row">
          {{ p(row.item.dispatchedCount, row.item.totalCount) }}
        </template>
        <template #cell(brokerFee)="row">
          {{ row.item.dispatchedCount > 0 ? Math.round(row.item.gross / row.item.dispatchedCount) : 0 }}
        </template>
        <template #cell(name)="data">
          <b-link v-if="data.item.userId" :to="userLink(data.item.userId)" target="_blank">{{ data.item.name }}</b-link>
        </template>

        <template #cell(show_details)="row">
          <b-btn size="xs" variant="secondary" @click.prevent="toggleAgentDetails(row)">
            {{ row.detailsShowing ? 'Hide' : 'Zoom' }}
          </b-btn>
        </template>

        <template #row-details="row">
          <b-card>
            <LoadingIndicator v-if="row.item.loading" centered />
            <b-table v-if="row.item.rows.length" striped hover :items="row.item.rows" :fields="detailsFields" :tbody-tr-class="rowClass" class="mb-0">
              <template #cell(id)="data">
                <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
              </template>
              <template #cell(priority)="data">
                {{ priorityName(data.value) }}
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>
    </b-card>



    <b-card class="mb-4" v-if="!loading && reflowsItems.length">
      <h5>Lead reflows report</h5>
      <b-table striped hover :items="reflowsItems" :fields="reflowsFields">
        <template #cell(p0)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p0List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p1)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p1List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p2)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p2List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p3)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p3List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(p4)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.p4List } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(freshBookedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.item.freshBookedCount, row.item.freshCount) }}</span>
        </template>
        <template #cell(freshCount)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.freshList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(leadsCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
        </template>
        <template #cell(quotesCount)="row">
          <b-link :to="{ name: 'QuoteList', query: { leadList: row.item.quotesList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(ordersCount)="row">
          <b-link :to="{ name: 'OrderList', query: { leadList: row.item.ordersList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>
        <template #cell(canceledCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(dispatchedCount)="row">
          {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.ordersCount) }}</span>
        </template>
        <template #cell(ratio)="row">
          {{ p(row.item.dispatchedCount, row.item.totalCount) }}
        </template>
        <template #cell(brokerFee)="row">
          {{ row.item.dispatchedCount > 0 ? Math.round(row.item.gross / row.item.dispatchedCount) : 0 }}
        </template>

        <template #cell(unclaimed)="row">
          <b-link :to="{ name: 'LeadList', query: { leadList: row.item.unclaimedList } }" target="_blank">
            {{ row.value }} <span class="ml-1">{{ p(row.value, row.item.totalCount) }}</span>
          </b-link>
        </template>

        <template #cell(show_details)="row">
          <b-btn size="xs" variant="secondary" @click.prevent="toggleReflowDetails(row)">
            {{ row.detailsShowing ? 'Hide' : 'Zoom' }}
          </b-btn>
        </template>

        <template #row-details="row">
          <b-card>
            <LoadingIndicator v-if="row.item.loading" centered />
            <b-table v-if="row.item.rows.length" striped hover :items="row.item.rows" :fields="detailsFields" :tbody-tr-class="rowClass" class="mb-0">
              <template #cell(id)="data">
                <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
              </template>
              <template #cell(priority)="data">
                {{ priorityName(data.value) }}
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>
    </b-card>

    <h5>Excel leads export</h5>

    <b-card class="mb-4">
      <b-form inline class="justify-content-between align-items-start w-100" @submit.prevent="excelExport">
        <div class="d-flex flex-column align-items-start">
          <label>Creation Date</label>
          <div class="d-inline-flex align-items-center mt-2">
            <b-form-radio v-model="periodTypeExport" name="periodTypeExport" value="list">Time period</b-form-radio>
            <b-select class="custom-select ml-3" v-if="periodTypeExport == 'list'" v-model="timePeriodExport" :options="timeOptions" />
          </div>
          <div class="d-inline-flex align-items-center mt-2">
            <b-form-radio v-model="periodTypeExport" name="periodTypeExport" value="custom">Custom range</b-form-radio>
            <flat-pickr class="form-control ml-2" placeholder="Select date range" v-if="periodTypeExport != 'list'" v-model="selectedDatesExport" :config="fltConfig" />
          </div>
        </div>

        <div class="d-flex flex-column align-items-start">
          <label>Type</label>
          <div class="text-left w-100 mt-3">
            <b-dropdown id="dropdown-form" size="md" ref="dropdown" variant="outline-secondary" class="mt-1">
              <template #button-content>
                Selected Types:
                <span v-if="types.includes('1')">L</span>
                <span v-if="types.includes('2')">Q</span>
                <span v-if="types.includes('3')">O</span>
                <span v-if="!types.length">None</span>
              </template>
              <b-dropdown-form>
                <div class="mb-3 custom-control custom-checkbox">
                  <input @click="setType('1')" class="custom-control-input" id="filter-leads" type="checkbox" :checked="getType('1')" />
                  <label for="filter-leads" class="custom-control-label">
                    <div>Leads</div>
                  </label>
                </div>
                <div class="mb-3 custom-control custom-checkbox">
                  <input @click="setType('2')" class="custom-control-input" id="filter-quotes" type="checkbox" :checked="getType('2')" />
                  <label for="filter-quotes" class="custom-control-label">
                    <div>Quotes</div>
                  </label>
                </div>
                <div class="mb-3 custom-control custom-checkbox">
                  <input @click="setType('3')" class="custom-control-input" id="filter-orders" type="checkbox" :checked="getType('3')" />
                  <label for="filter-orders" class="custom-control-label">
                    <div>Orders</div>
                  </label>
                </div>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>

        <div class="d-flex flex-column align-items-start">
          <label>Sources</label>
          <div class="text-left w-100 mt-3">
            <b-dropdown id="dropdown-form-sources" size="md" ref="dropdown2" variant="outline-secondary" class="mt-1">
              <template #button-content>
                Selected Sources:
                <span>{{ selectedSources.length }}</span>
              </template>
              <b-dropdown-form>
                <div class="mb-3 custom-control custom-checkbox" v-for="source in sources" :key="source.value">
                  <input @click="selectSource(source.value)" class="custom-control-input" :id="'filter-sources-' + source.value" type="checkbox" :checked="checkSource(source.value)" />
                  <label :for="'filter-sources-' + source.value" class="custom-control-label">
                    <div>{{ source.label }}</div>
                  </label>
                </div>
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </div>

        <div class="d-flex flex-column align-items-start">
          <label>Move Date</label>
          <div class="d-inline-flex align-items-center mt-2">
            <flat-pickr class="form-control" v-model="selectedDatesMoveDate" placeholder="Custom Move Date" :config="fltConfig" />
          </div>
        </div>

        <div class="d-flex flex-column align-items-start">
          <label>Priority</label>
          <div class="d-inline-flex w-100 justify-content-start gap-20 flex-wrap mt-3">
            <b-form-checkbox v-model="unclaimed" name="unclaimed" value="true"> Unclaimed </b-form-checkbox>
            <b-form-checkbox id="nopriority" @change="selectPriority(0)" value="0"> No Priority </b-form-checkbox>
            <b-form-checkbox id="hot" @change.native="selectPriority(1)" value="1"> Hot </b-form-checkbox>
            <b-form-checkbox id="future" @change.native="selectPriority(2)" value="2"> Future </b-form-checkbox>
            <b-form-checkbox id="booked" @change.native="selectPriority(3)" value="3"> Booked </b-form-checkbox>
            <b-form-checkbox id="bad" @change.native="selectPriority(4)" value="4"> Bad </b-form-checkbox>
          </div>
        </div>

        <div class="d-flex flex-column align-items-start basis-15">
          <label>Pickup State</label>
          <div class="text-left w-100 mt-3">
              <multiselect
                v-model="pickupState"
                track-by="value"
                label="label"
                placeholder="Type to search state"
                :options="statesDropdown"
                :allow-empty="true"
                :multiple="true"
                :searchable="true"
                :show-labels="false"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="true"
              >
                <span slot="noResult">None selected</span>
              </multiselect>
          </div>
        </div>

        <div class="d-flex flex-column align-items-start basis-15">
          <label>Delivery State</label>
          <div class="text-left w-100 mt-3">
              <multiselect
                v-model="deliveryState"
                track-by="value"
                label="label"
                placeholder="Type to search state"
                :options="statesDropdown"
                :allow-empty="true"
                :multiple="true"
                :searchable="true"
                :show-labels="false"
                :internal-search="true"
                :clear-on-select="false"
                :close-on-select="true"
              >
                <span slot="noResult">None selected</span>
              </multiselect>
          </div>
        </div>

        <button class="btn btn-primary">Export</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loadingExport" centered />
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import statesMixin from '@/mixins/states';

export default {
  name: 'ReportLeads',
  data() {
    return {
      reportPickupState: null,
      reportDeliveryState: null,
      pickupState: null,
      deliveryState: null,
      loading: false,
      loadingExport: false,
      agentsItems: [],
      sourcesItems: [],
      sourcesTotals: {},
      referralsItems: [],
      reflowsItems: [],
      periodType: 'list',
      timePeriod: 1,
      selectedDates: null,
      periodTypeExport: 'list',
      timePeriodExport: 1,
      selectedDatesExport: null,
      selectedDatesMoveDate: null,
      selectedPriorities: [],
      unclaimed: false,
      types: [],
      sources: [],
      selectedSources: [],
      form: {
        createdStart: null,
        createdEnd: null,
        pickupState: null,
        deliveryState: null,
      },
      fltConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: true,
      },
      timeOptions: [
        { text: 'Current month', value: 1 },
        { text: 'Last month', value: 2 },
        { text: 'Current quarter', value: 3 },
        { text: 'Last quarter', value: 4 },
        { text: 'Current year', value: 5 },
        { text: 'All time', value: 6 },
      ],
      agentsFields: [
        { key: 'name', label: 'User', sortable: true },
        { key: 'p0', label: 'No Priority', sortable: true },
        { key: 'p1', label: 'Hot', sortable: true },
        { key: 'p2', label: 'Future', sortable: true },
        { key: 'p3', label: 'Booked', sortable: true },
        { key: 'p4', label: 'Bad', sortable: true },
        { key: 'freshCount', label: 'Fresh', sortable: true },
        { key: 'freshBookedCount', label: 'Fresh Conv', sortable: true },
        { key: 'leadsCount', label: 'Leads', sortable: true },
        { key: 'quotesCount', label: 'Quotes', sortable: true },
        { key: 'ordersCount', label: 'Orders', sortable: true },
        { key: 'totalCount', label: 'Total', sortable: true },
        { key: 'dispatchedCount', label: 'Dispatched', sortable: true },
        { key: 'canceledCount', label: 'Canceled', sortable: true },
        { key: 'competitionCount', label: 'Competition', sortable: true },
        { key: 'ratio', label: 'Conversion Ratio', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.dispatchedCount / item.totalCount * 100) : 0 }},
        { key: 'gross', label: 'Gross Profit', sortable: true },
        { key: 'brokerFee', label: 'Avg Broker Fee', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.gross / item.dispatchedCount) : 0  } },
        { key: 'show_details', label: '', sortable: false },
      ],
      sourcesFields: [
        { key: 'name', label: 'Source', sortable: true },
        { key: 'unclaimed', label: 'Unclaimed', sortable: true },
        { key: 'p0', label: 'No Priority', sortable: true },
        { key: 'p1', label: 'Hot', sortable: true },
        { key: 'p2', label: 'Future', sortable: true },
        { key: 'p3', label: 'Booked', sortable: true },
        { key: 'p4', label: 'Bad', sortable: true },
        { key: 'freshCount', label: 'Fresh', sortable: true },
        { key: 'freshBookedCount', label: 'Fresh Conv', sortable: true },
        { key: 'leadsCount', label: 'Leads', sortable: true },
        { key: 'quotesCount', label: 'Quotes', sortable: true },
        { key: 'ordersCount', label: 'Orders', sortable: true },
        { key: 'totalCount', label: 'Total', sortable: true },
        { key: 'dispatchedCount', label: 'Dispatched', sortable: true },
        { key: 'canceledCount', label: 'Canceled', sortable: true },
        { key: 'ratio', label: 'Conversion Ratio', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.dispatchedCount / item.totalCount * 100) : 0 }},
        { key: 'gross', label: 'Gross Profit', sortable: true },
        { key: 'brokerFee', label: 'Avg Broker Fee', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.gross / item.dispatchedCount) : 0  } },
        { key: 'show_details', label: '', sortable: false },
      ],
      referralsFields: [
        { key: 'source', label: 'Source', sortable: true },
        { key: 'referral', label: 'Referral', sortable: true },
        { key: 'unclaimed', label: 'Unclaimed', sortable: true },
        { key: 'p0', label: 'No Priority', sortable: true },
        { key: 'p1', label: 'Hot', sortable: true },
        { key: 'p2', label: 'Future', sortable: true },
        { key: 'p3', label: 'Booked', sortable: true },
        { key: 'p4', label: 'Bad', sortable: true },
        { key: 'freshCount', label: 'Fresh', sortable: true },
        { key: 'freshBookedCount', label: 'Fresh Conv', sortable: true },
        { key: 'leadsCount', label: 'Leads', sortable: true },
        { key: 'quotesCount', label: 'Quotes', sortable: true },
        { key: 'ordersCount', label: 'Orders', sortable: true },
        { key: 'totalCount', label: 'Total', sortable: true },
        { key: 'dispatchedCount', label: 'Dispatched', sortable: true },
        { key: 'canceledCount', label: 'Canceled', sortable: true },
        { key: 'ratio', label: 'Conversion Ratio', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.dispatchedCount / item.totalCount * 100) : 0 }},
        { key: 'gross', label: 'Gross Profit', sortable: true },
        { key: 'brokerFee', label: 'Avg Broker Fee', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.gross / item.dispatchedCount) : 0  } },
        { key: 'show_details', label: '', sortable: false },
      ],
      reflowsFields: [
        { key: 'name', label: 'Source', sortable: true },
        { key: 'unclaimed', label: 'Unclaimed', sortable: true },
        { key: 'p0', label: 'No Priority', sortable: true },
        { key: 'p1', label: 'Hot', sortable: true },
        { key: 'p2', label: 'Future', sortable: true },
        { key: 'p3', label: 'Booked', sortable: true },
        { key: 'p4', label: 'Bad', sortable: true },
        { key: 'freshCount', label: 'Fresh', sortable: true },
        { key: 'freshBookedCount', label: 'Fresh Conv', sortable: true },
        { key: 'leadsCount', label: 'Leads', sortable: true },
        { key: 'quotesCount', label: 'Quotes', sortable: true },
        { key: 'ordersCount', label: 'Orders', sortable: true },
        { key: 'totalCount', label: 'Total', sortable: true },
        { key: 'dispatchedCount', label: 'Dispatched', sortable: true },
        { key: 'canceledCount', label: 'Canceled', sortable: true },
        { key: 'ratio', label: 'Conversion Ratio', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.dispatchedCount / item.totalCount * 100) : 0 }},
        { key: 'gross', label: 'Gross Profit', sortable: true },
        { key: 'brokerFee', label: 'Avg Broker Fee', sortable: true, sortByFormatted: true, formatter: (value, key, item) => { return item.dispatchedCount > 0 ? Math.round(item.gross / item.dispatchedCount) : 0  } },
        { key: 'show_details', label: '', sortable: false },
      ],
      detailsFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'source',
          label: 'Source',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'priority',
          label: 'priority',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'tarrifs',
          label: 'Tarrif',
          sortable: true,
        },
        {
          key: 'customerPaidCompany',
          label: 'Deposit paid',
          sortable: true,
        },
        {
          key: 'carrierPay',
          label: 'Carrier pay',
          sortable: true,
        },
        {
          key: 'pendingDeposit',
          label: 'Unpaid deposit',
          sortable: true,
        },
        {
          key: 'signedAt',
          label: 'Dispatched At',
          sortable: true,
        },
      ],
    };
  },
  mixins: [tableFields, permissionsMixin, statesMixin],
  components: {
    LoadingIndicator,
    flatPickr,
    Multiselect,
  },
  computed: {
    statesDropdown() {
      return this.stateGroups.concat(this.states);
    },
  },
  methods: {
    async fetchSources() {
      const response = await ApiClient.sources.get({});
      if (response.status == 200) {
        this.sources = response.data.data.map(u => ({ value: u.id, label: u.name }));
      }
    },
    selectSource(sourceId) {
      let index = this.selectedSources.indexOf(sourceId);
      if (index == -1) {
        this.selectedSources.push(sourceId);
      } else {
        this.selectedSources.splice(index, 1);
      }
    },
    checkSource(sourceId) {
      let index = this.selectedSources.indexOf(sourceId);
      if (index == -1) {
        return false;
      }
      return true;
    },
    setType(type) {
      let currtypes = this.types;
      if (currtypes.includes(type)) {
        var index = currtypes.indexOf(type);
        if (index !== -1) {
          currtypes.splice(index, 1);
        }
      } else {
        currtypes.push(type);
      }
    },
    getType(type) {
      if (this.types.includes(type)) {
        return type;
      }
      return false;
    },
    p(x, y) {
      if (!y || y / 1 < 1) return '';
      if (!x || x / 1 < 1) return '';
      return '(' + Math.round((x * 100) / y) + '%)';
    },
    async submit(e) {
      e.preventDefault();
      this.fetchReports();
    },
    async fetchReports() {
      this.prepareDates();
      this.loading = true;

      let res = await Promise.all([
        ApiClient.reports.leadsAgents({ params: this.form }),
        ApiClient.reports.leadsSources({ params: this.form }),
        ApiClient.reports.leadsReferrals({ params: this.form }),
        ApiClient.reports.leadsReflows({ params: this.form }),
      ]);

      this.agentsItems = res[0].data.data.map(x => {
        x.rows = [];
        x.loading = false;
        return x;
      });
      this.sourcesItems = res[1].data.data.rows.map(x => {
        x.rows = [];
        x.loading = false;
        return x;
      });
      this.sourcesTotals = res[1].data.data.totals;
      this.referralsItems = res[2].data.data.map(x => {
        x.rows = [];
        x.loading = false;
        return x;
      });
      this.reflowsItems = res[3].data.data.map(x => {
        x.rows = [];
        x.loading = false;
        return x;
      });

      this.loading = false;
    },

    toggleDetails(row) {
      row.toggleDetails();
      if (row.item._showDetails) {
        this.fetchSourceDetails(row.item);
      }
    },
    toggleAgentDetails(row) {
      row.toggleDetails();
      if (row.item._showDetails) {
        this.fetchAgentDetails(row.item);
      }
    },
    toggleReferralDetails(row) {
      row.toggleDetails();
      if (row.item._showDetails) {
        this.fetchReferralDetails(row.item);
      }
    },
    toggleReflowDetails(row) {
      row.toggleDetails();
      if (row.item._showDetails) {
        this.fetchReflowDetails(row.item);
      }
    },

    async fetchSourceDetails(item) {
      this.prepareDates();
      item.loading = true;
      let params = Object.assign({ sourceId: item.sourceId }, this.form);
      const response = await ApiClient.reports.leadsSourcesDetails({ params });
      item.loading = false;
      item.rows = response.data.data.rows;
    },
    async fetchAgentDetails(item) {
      this.prepareDates();
      item.loading = true;
      let params = Object.assign({ userId: item.userId }, this.form);
      const response = await ApiClient.reports.leadsAgentsDetails({ params });
      item.loading = false;
      item.rows = response.data.data.rows;
    },
    async fetchReferralDetails(item) {
      this.prepareDates();
      item.loading = true;
      let params = Object.assign({ referral: item.referral, sourceId: item.sourceId }, this.form);
      const response = await ApiClient.reports.leadsReferralsDetails({ params });
      item.loading = false;
      item.rows = response.data.data.rows;
    },
    async fetchReflowDetails(item) {
      this.prepareDates();
      item.loading = true;
      let params = Object.assign({ sourceId: item.sourceId }, this.form);
      const response = await ApiClient.reports.leadsReflowsDetails({ params });
      item.loading = false;
      item.rows = response.data.data.rows;
    },
    orderLink(item) {
      if (item.leadTypeId == 3) return { name: 'OrderDetails', params: { id: item.id } };
      if (item.leadTypeId == 2) return { name: 'QuoteDetails', params: { id: item.id } };
      if (item.leadTypeId == 1) return { name: 'LeadDetails', params: { id: item.id } };
      return null;
    },
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
    prepareDates() {
      let parts = [];
      if (this.periodType == 'list') {
        if (this.timePeriod == 1) {
          parts.push(moment().startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 2) {
          parts.push(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 3) {
          parts.push(moment().startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 4) {
          parts.push(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 5) {
          parts.push(moment().startOf('year').format('YYYY-MM-DD'));
          parts.push(moment().endOf('year').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 6) {
          parts.push(null);
          parts.push(null);
        }
      } else {
        if (!this.selectedDates) return;
        parts = this.selectedDates.split(' to ');
        if (parts.length < 2) parts.push(parts[0]);
      }

      this.form.createdStart = parts[0];
      this.form.createdEnd = parts[1];

      this.form.pickupState = null;
      if(this.reportPickupState && this.reportPickupState.length){
        this.form.pickupState = this.reportPickupState.map(x => x.value).join(',');
      }
      this.form.deliveryState = null;
      if(this.reportDeliveryState && this.reportDeliveryState.length){
        this.form.deliveryState = this.reportDeliveryState.map(x => x.value).join(',');
      }
    },

    async excelExport() {
      let parts = [];
      this.loadingExport = true;
      if (this.periodTypeExport == 'list') {
        if (this.timePeriodExport == 1) {
          parts.push(moment().startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriodExport == 2) {
          parts.push(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriodExport == 3) {
          parts.push(moment().startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriodExport == 4) {
          parts.push(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriodExport == 5) {
          parts.push(moment().startOf('year').format('YYYY-MM-DD'));
          parts.push(moment().endOf('year').format('YYYY-MM-DD'));
        }
        if (this.timePeriodExport == 6) {
          parts.push(null);
          parts.push(null);
        }
      } else {
        if (!this.selectedDatesExport) return;
        parts = this.selectedDatesExport.split(' to ');
        if (parts.length < 2) parts.push(parts[0]);
      }
      let form = {};
      form.createdStart = parts[0];
      form.createdEnd = parts[1];
      if (this.unclaimed) {
        form.unclaimed = 1;
      }
      if (this.selectedDatesMoveDate) {
        var part = this.selectedDatesMoveDate.split(' to ');
        form.move_date_start = part[0];
        form.move_date_end = part[1];
      }
      if (this.selectedPriorities.length) {
        form.priority = this.selectedPriorities.join(',');
      }
      if (this.selectedSources.length) {        
        form.sources = this.selectedSources.join(',');
      }
      if (this.types.length) {
        form.lead_type = this.types.join(',');
      }

      if(this.pickupState && this.pickupState.length){
        form.pickupState = this.pickupState.map(x => x.value).join(',');
      }

      if(this.deliveryState && this.deliveryState.length){
        form.deliveryState = this.deliveryState.map(x => x.value).join(',');
      }

      let response = await ApiClient.reports.leadsExport({
        params: form,
        blobRespone: true,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'leads.csv');
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
      this.loadingExport = false;
    },
    selectPriority(id) {
      if (this.selectedPriorities.includes(id)) {
        const index = this.selectedPriorities.indexOf(id);
        if (index > -1) {
          this.selectedPriorities.splice(index, 1);
        }
      } else {
        this.selectedPriorities.push(id);
      }
    },
    rowClass(item, type) {
      if (type === 'row' && item && item.tasksExists) return 'text-danger';
      return null;
    },
    priorityName(x) {
      if (x == 0) return 'No Priority';
      if (x == 1) return 'Hot';
      if (x == 2) return 'Future';
      if (x == 3) return 'Booked';
      if (x == 4) return 'Bad';
      return null;
    },
  },
  async mounted() {
    this.fetchSources();
  },
};
</script>

<style lang="scss" scoped>
.v-border {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 70%;
    top: 17%;
    background-color: #dddddd;
    right: 24px;
  }
}

.basis-35 {
  flex-basis: 27%;
}
.basis-30 {
  flex-basis: 23%;
}

.basis-10 {
  flex-basis: 4%;
}

.basis-15 {
  flex-basis: 15%;
}
.gap-20 {
  gap: 5px 8px;
}
</style>

<style lang="scss">
#dropdown-form-sources {
  .dropdown-menu {
    min-width: 300px;
    max-height: 400px;
    overflow: auto;
  }
}
</style>