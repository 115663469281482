<template>
  <div class="card mb-4">
    <div class="card-header d-flex justify-content-between" style="cursor: pointer;" @click="open= !open">
      <h5 class="mb-0"><slot name="header"></slot></h5>
      <div class="dropdown-toggle" :class="{'rotate-180': open}">
      </div>
    </div>
    <div class="card-body" v-if="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  data() {
    return {
      open: true
    }
  }
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
</style>