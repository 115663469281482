export const orderSources = [
  { value: 1, label: 'Nexus Website', visible: true },
  { value: 2, label: 'networkmoving.com' },
  { value: 3, label: 'irelo.com' },
  { value: 4, label: 'autotransportdepot.com' },
  { value: 5, label: 'irelocation.com' },
  { value: 6, label: 'leadartisans.com' },
  { value: 7, label: 'carshipguru.com' },
  { value: 8, label: 'moving.com' },
  { value: 9, label: 'vade.media' },
  { value: 10, label: 'autotransport411.com' },
  { value: 11, label: 'ibilitymedia.com' },
  { value: 12, label: 'Sales Line', visible: true },
  { value: 13, label: 'Super Hot Sales Line', visible: true },
  { value: 14, label: 'Ibility Sales Line', visible: true },
  { value: 15, label: 'Ireloconnect', visible: true },
 // { value: 16, label: 'Moving Company Referral', visible: true },
  { value: 17, label: 'Customer Referral', visible: true },
  { value: 18, label: 'Returning Customer', visible: true },
  { value: 32, label: 'Regular Customer', visible: true },
 // { value: 36, label: 'G sales line', visible: true },
  { value: 34, label: 'Three Ships', visible: true },
  { value: 35, label: 'Three Ships Line', visible: true },
  { value: 37, label: 'Consumer Voice Line', visible: true },
  { value: 44, label: 'Spotower Sales Line', visible: true },
  { value: 20, label: 'iRelo Auto 5' },
  { value: 21, label: 'Google Organic' },
  { value: 22, label: 'Google PAID ads' },
  { value: 23, label: 'Bing' },
  { value: 24, label: 'Car Talk', visible: true },
 // { value: 25, label: 'Autotempest', visible: true },
  { value: 26, label: 'Quote Email' },
  { value: 27, label: 'Quote SMS' },
  { value: 28, label: 'Forbes Sales Line', visible: true },
  { value: 29, label: 'Rexdirect' },
  { value: 30, label: 'Rexdirect Exclusive' },
//  { value: 31, label: 'Hot Sales Call', visible: true },
  { value: 39, label: 'Angi Sales'},
  { value: 19, label: 'Other', visible: true },
];

export default {
  data() {
    return {
      orderSources,
    };
  },
  methods: {
    paymentTypeLabel(value) {
      return orderSources.find(pt => pt.value == value).label;
    },
  },
};
