<template>
  <tbody>
    <tr>
      <td class="align-middle">{{ editableDriver.name }}</td>
      <td class="align-middle">
        <a :href="'tel:' + editableDriver.phone">{{ editableDriver.phone | formatPhone}}</a>
      </td>
      <td>
        <button class="btn btn-sm float-right"  :class="{'btn-primary': open, 'btn-outline-primary': !open, 'invisible': editMode}" @click="open = !open">
          {{ open ? 'Less' : 'More' }}
        </button>
      </td>
    </tr>


    <transition name="fade">
      <tr v-if="open">
        <td colspan="3" class="p-0">
          <div v-if="!editMode" class="p-3 bg-light d-flex flex-column" style="gap: 20px;">
            <div class="d-flex flex-column" style="gap: 20px;">
              <div class="d-flex" style="gap: 20px;">
                <div style="width: 64px;"><b>Name</b></div>
                <div>{{ editableDriver.name }} </div>
              </div>
              <div class="d-flex" style="gap: 20px;">
                <div style="width: 64px;"><b>Number</b></div>
                <div>
                  <a :href="'tel:' + editableDriver.phone">{{ editableDriver.phone | formatPhone}}</a></div>
              </div>
            </div>
            <div class="d-flex flex-column" style="gap: 8px;">

              <div><b>License Image</b></div>

              <div v-if="licenseImages && licenseImages.length" class="img-container">
                <ImageCard v-for="data in licenseImages" :key="data.id" :url="public_url + data.url"></ImageCard>
              </div>
              <span v-else>No license image</span>
            </div>

            <div class="d-flex flex-column" style="gap: 8px;">

              <div><b>Truck Images</b></div>

              <div v-if="truckImages && truckImages.length" class="img-container">
                <ImageCard  v-for="data in truckImages" :key="data.id" :url="public_url + data.url"/>
              </div>
              <span v-else>No truck images</span>
            </div>

            <div>
              <button class="btn btn-outline-primary btn-sm" @click="editMode = true"><i class="fas fa-edit"></i> Edit</button>
              <button class="btn btn-outline-danger btn-sm ml-2" @click="$emit('delete')"><i class="fas fa-trash"></i> Delete</button>
            </div>

          </div>

          <div v-else>
            <DriverForm :driver="editableDriver" :is-editing="true" :licenses="licenseImages" :trucks="truckImages"  :public_url="public_url" :carrierId="carrierId" @save="(val) => saveEdit(val)" @cancel="editMode = false" />
          </div>
        </td>
      </tr>
    </transition>
  </tbody>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import DriverForm from './DriverForm.vue';
import ImageCard from './ImageCard.vue';

export default {
  name: 'DriverRow',
  components: { DriverForm, ImageCard },
  props: {
    driver: {
      type: Object,
      required: true,
    },
    carrierId: {
      type: Number,
      required: true,
    },
    public_url: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      open: false,
      editMode: false,
      editableDriver: { ...this.driver },
      licenseImages: [],
      truckImages: [],
    };
  },
  async mounted() {
    await this.getLicenses();
    await this.getTrucks();
  },
  methods: {
    async getLicenses() {
      const apiResponse = await ApiClient.carriers.drivers.get_licenses({ carrierId: this.carrierId, driverId: this.driver.id });
      if (apiResponse.data && apiResponse.data.data) {
        this.licenseImages = apiResponse.data.data;
      }
    },
    async getTrucks() {
      const apiResponse = await ApiClient.carriers.drivers.get_trucks({ carrierId: this.carrierId, driverId: this.driver.id });
      if (apiResponse.data && apiResponse.data.data) {
        this.truckImages = apiResponse.data.data;
      }
    },
    cancelEdit() {
      this.editableDriver = { ...this.driver };
      this.editMode = false;
    },
    async saveEdit(val) {
      await this.getLicenses();
      await this.getTrucks();
      this.$emit('updateDriver', val);
      this.editMode = false;
    },
  },
   watch: {
    driver: {
      handler(newDriver) {
        this.editableDriver = { ...newDriver }
      },
      deep: true,
    }
  },
};
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.img-container {
  position: relative;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

</style>
