<template>
  <div>
    <div class="d-flex justify-content-between align-items-center py-2">
      <h1>Sales mode settings</h1>
    </div>
    <LoadingIndicator v-if="loading" centered />

    <b-card class="mb-4">
      <b-table v-if="users.length" striped hover :items="users" :fields="userFields">
        <template #cell(actions)="data">
          <b-btn size="xs" variant="danger" @click.prevent="removeUser(data.item.id)">Remove</b-btn>
        </template>
        <template #cell(isLasting)="data">
          {{ data.value ? 'Yes' : 'No' }}
        </template>
        <template #cell(isOnline)="data">
          {{ data.value ? 'Yes' : 'No' }}
        </template>
      </b-table>

      <b-form inline class="my-4" @submit.prevent="submitUsers">
        <b-form-group>
          <UserDropdown @select="setUser" :value="addUser.userId" :show-empty="false" />
        </b-form-group>
        <b-form-group>
          <b-select v-model="addUser.isLasting" :options="lastingOptions" />
        </b-form-group>
        <b-button variant="outline-primary" type="submit" class="mx-2">Add user</b-button>
      </b-form>
    </b-card>

    <b-card class="mb-4">
      <b-form @submit.prevent="submitSources">
        <b-table v-if="sources.length" striped hover :items="sources" :fields="sourceFields">
          <template #cell(dashboardDelay)="data">
            <b-input v-model="data.item.dashboardDelay" />
          </template>
          <template #cell(dashboardFlow)="data">
            <b-form-group>
              <b-select v-model="data.item.dashboardFlow" :options="sourceOptions" />
            </b-form-group>
          </template>
          <template #cell(calculatorDiscount)="data">
            <b-input v-model="data.item.calculatorDiscount" />
          </template>
        </b-table>
        <button class="btn btn-primary">Save</button>
      </b-form>
    </b-card>

    <b-card class="mb-4">
      <b-form @submit.prevent="submitSettings">
        <b-form-group label="Sales mode">
          <b-select v-model="jsonValue.enabled" :options="enabledOptions" />
        </b-form-group>
        <b-form-group label="Minimum agents online">
          <b-input v-model="jsonValue.agents_online" />
        </b-form-group>
        <b-form-group label="Time start (hh:mm)">
          <b-input v-model="jsonValue.time_start" placeholder="hh:mm" />
        </b-form-group>
        <b-form-group label="Time end (hh:mm)">
          <b-input v-model="jsonValue.time_end" placeholder="hh:mm" />
        </b-form-group>
        <button class="btn btn-primary">Save</button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import UserDropdown from '@/components/shared/UserDropdown';

export default {
  name: 'LeadsSettings',
  data() {
    return {
      enabledOptions: [
        { text: 'Enabled', value: true },
        { text: 'Disabled', value: false },
      ],
      lastingOptions: [
        { text: 'Always in DB1', value: true },
        { text: 'This week only', value: false },
      ],
      sourceOptions: [
        { text: 'Flow both DB', value: 0 },
        { text: 'Flow DB1 only', value: 1 },
        { text: 'Flow DB2 only', value: 2 },
      ],
      settings: {},
      jsonValue: { enabled: false, agents_online: 0, time_start: null, time_end: null }, // no case casting here as this comes from JSON string
      loading: false,
      users: [],
      addUser: {
        userId: null,
        isLasting: false,
      },
      sources: [],
      sourcesOld: [],
      sourceFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'dashboardDelay',
          label: 'Dashboard delay (zero = instant flow)',
          sortable: true,
        },
        {
          key: 'dashboardFlow',
          label: 'Dashboard flow',
          sortable: true,
        },
        {
          key: 'calculatorDiscount',
          label: 'Calculator discount',
          sortable: true,
        },
      ],
      userFields: [
        {
          key: 'user.name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'isLasting',
          label: 'Always in DB1',
          sortable: true,
        },
        {
          key: 'isOnline',
          label: 'Active',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ],
    };
  },
  components: {
    LoadingIndicator,
    UserDropdown,
  },
  methods: {
    async submitUsers(e) {
      e.preventDefault();
      this.loading = true;
      await ApiClient.dashboardUsers.post({ body: this.addUser });
      this.addUser = Object.assign(this.addUser, { userId: null, isLasting: false });

      await this.fetchUsers();
      this.loading = false;
    },
    async submitSettings(e) {
      e.preventDefault();
      this.saveSettings();
    },
    async submitSources(e) {
      e.preventDefault();
      this.saveSources();
    },
    setUser(userId) {
      this.addUser.userId = userId;
    },
    async removeUser(userId) {
      this.loading = true;
      await ApiClient.dashboardUsers.delete({ id: userId });
      await this.fetchUsers();
      this.loading = false;
    },
    async fetchUsers() {
      let response = await ApiClient.dashboardUsers.get({});
      this.users = response.data.data;
    },
    async fetch() {
      this.loading = true;
      let response = await ApiClient.settings.get({ name: 'sales_mode' });

      this.settings = Object.assign(this.settings, response.data.data);
      this.jsonValue = Object.assign(this.jsonValue, JSON.parse(this.settings.jsonValue));

      response = await ApiClient.sources.get();
      this.sources = response.data.data.filter(x => x.isActive);
      this.sourcesOld = JSON.parse(JSON.stringify(this.sources));

      await this.fetchUsers();

      this.loading = false;
    },
    async saveSettings() {
      this.loading = true;
      this.settings.jsonValue = JSON.stringify(this.jsonValue);
      const response = await ApiClient.settings.put({ name: 'sales_mode', body: this.settings });
      this.loading = false;
      this.settings = Object.assign(this.settings, response.data.data);
      this.jsonValue = Object.assign(this.jsonValue, JSON.parse(this.settings.jsonValue));
    },

    async saveSources() {
      this.loading = true;
      for (let item of this.sources) {
        if (this.sourcesOld.some(x => x.id == item.id && x.dashboardDelay == item.dashboardDelay && x.dashboardFlow == item.dashboardFlow && x.calculatorDiscount == item.calculatorDiscount)) continue;
        if (item.dashboardDelay === '') item.dashboardDelay = null;
        let data = { id: item.id, dashboardDelay: item.dashboardDelay, dashboardFlow: item.dashboardFlow, calculatorDiscount: item.calculatorDiscount };
        await ApiClient.sources.put({ id: item.id, body: data });
      }
      this.sourcesOld = JSON.parse(JSON.stringify(this.sources));
      this.loading = false;
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
