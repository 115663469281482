<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <div class="input-group-text">
        <span class="fas fa-search"></span>
      </div>
    </div>
    <input 
      class="form-control" 
      :placeholder="placeholder"
      :value="searchValue"
      @input="updateSearch"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search...',
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    updateSearch(event) {
      this.searchValue = event.target.value;
    }
  }
};
</script>