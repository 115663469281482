<template>
  <div class="position-relative">
    <b-form-group :label="label" class="mr-3 mb-2"><b-input v-model="inputValue" @input="newValue => handleInputChange(newValue)" type="text" class="zip" /></b-form-group>
    <div class="card p-0 mb-3 position-absolute " v-if="show" style="z-index: 1000; min-width: 100%; width: max-content;">
      <LoadingIndicator v-if="loading" centered />
      <div v-if="!loading">
        <ul class="list-group">
          <li class="list-group-item" v-if="suggestions.length">
            <b>Suggestions</b>
          </li>
          <li class="list-group-item"  v-for="item in suggestions"  :key="item.zip + item.city" @click="handleSuggestion(item)" style="cursor: pointer;">
            {{ item.city }}, {{ item.state }} {{ item.zip }}
          </li>
          <li v-if="!suggestions.length">No Suggestions Available</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import { debounce } from 'debounce';
import { ApiClient } from '@/api/ApiClient';

const searchSuggestions = debounce(async (searchTerm, callback) => {
  callback(await ApiClient.locations.get({ params: { cdq: searchTerm } }));
}, 200);

export default {
  components: {
    LoadingIndicator,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  data() {
    return {
      suggestions: [],
      suggestionsCount: 10,
      loading: false,
      show: false,
    };
  },
  methods: {
    isNumeric(str) {
      return /^\d+$/.test(str);
    },
    handleInputChange(newValue ) {
      this.inputValue = newValue

      const isNumber = this.isNumeric(newValue);
      const searchTerm = isNumber ? `${newValue},` : `,${newValue}`;

      this.searhCityZipState(searchTerm.trim());
    },
    handleSuggestion(item) {
      this.inputValue  = item.zip
      this.suggestions = [];
      this.show = false;
    },
    async searhCityZipState(searchTerm) {
      console.log(searchTerm);
      if (searchTerm.length > 2) {
        this.show = true;
        this.loading = true;
        searchSuggestions(searchTerm, apiResponse => {
          this.suggestions = apiResponse.data.data.slice(0, this.suggestionsCount);
          this.loading = false;
        });
      } else {
        this.suggestions = [];
        this.show = false;
      }
    },
  },
};
</script>