<template>
  <div>
    <ul class="nav nav-tabs mb-4">
      <li class="nav-item"><b-link :to="{ name: 'MarketRates'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRates' }">Market Rates</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'ActiveRoutes'}" :class="{ 'nav-link': true, active: this.$route.name === 'ActiveRoutes' }">Active routes</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'MarketRatesMap'}" :class="{ 'nav-link': true, active: this.$route.name === 'MarketRatesMap' }">Location map</b-link></li>
      <li class="nav-item"><b-link :to="{ name: 'Calculator'}" :class="{ 'nav-link': true, active: this.$route.name === 'Calculator' }">Calculator</b-link></li>
    </ul>

    <market-rates-list :default-pickup-zip="$route.query.pickupZip" :default-delivery-zip="$route.query.deliveryZip" />
  </div>
</template>

<script>
import MarketRatesList from '@/components/shared/MarketRatesList';

export default {
  name: 'MarketRates',
  computed: {
    params() {
      const r = {};
      const { pickupZip, deliveryZip } = this.$route.query;
      r.pickupZip = pickupZip;
      r.deliveryZip = deliveryZip;
      return r;
    },
  },
  components: {
    MarketRatesList,
  },
};
</script>

<style lang="scss" scoped></style>
