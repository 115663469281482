<template>
  <div v-if="!isHidden">
    <div v-if="!internalLink && !externalLink">
      <b>{{ label }}:</b> <span :class="textColor">{{ formattedValue }}</span>
    </div>
    <div v-else>
      <b>{{ label }}:</b>
      <span :class="textColor">
        <template v-if="externalLink">
          <a :href="externalLink" target="_blank" rel="noopener noreferrer">{{ formattedValue }}</a>
        </template>
        <template v-else>
          <b-link :to="internalLink">{{ formattedValue }}</b-link>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '-',
    },
    money: {
      type: Boolean,
      default: false,
    },
    hideIfBlank: {
      type: Boolean,
      default: false,
    },
    internalLink: {
      type: Object,
      default: null,
    },
    externalLink: {
      type: String,
      default: ''
    }
  },
  computed: {
    formattedValue() {
      return this.money ? `$${this.value || 0}` : this.value;
    },
    isBlank() {
      return this.value?.toString().length === 0;
    },
    isHidden() {
      return this.hideIfBlank && this.isBlank;
    },
  },
};
</script>

<style lang="scss" scoped></style>
