import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import auth from './modules/auth';
import leads from './modules/leads';
import notifications from './modules/notifications';
import templates from './modules/templates';
import pagination from './modules/pagination';
import users from './modules/users';
import carrier from './modules/carrier';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    leads,
    notifications,
    users,
    templates,
    pagination,
    carrier
  },
  debug: process.env.NODE_ENV === 'development',
});
