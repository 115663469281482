<template>
  <div>
    <div class="nav-responsive-lg">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <router-link :class="{ 'nav-link': true, active: $route.name == 'LeadList' && $route.query.userId == undefined }" :to="{ name: 'LeadList' }"> Leads </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="{ 'nav-link': true, active: $route.name == 'LeadList' && $route.query.userId && !$route.query.hasFollowup }"
            :to="{ name: 'LeadList', query: { userId: $store.state.auth.user.id } }"
          >
            My Leads
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :class="{ 'nav-link': true, active: $route.name == 'LeadList' && $route.query.userId == '' }" :to="{ name: 'LeadList', query: { userId: ''} }"> Unclaimed </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="{ 'nav-link': true, active: $route.name == 'LeadList' && $route.query.userId && $route.query.hasFollowup }"
            :to="{ name: 'LeadList', query: { userId: $store.state.auth.user.id, hasFollowup: 1 } }"
          >
            Follow-Ups
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :class="{ 'nav-link': true, active: $route.name == 'LeadsArchived' }" :to="{ name: 'LeadsArchived' }"> Archived </router-link>
        </li>
        <li v-if="tokenCan('admin')" class="nav-item">
          <router-link :class="{ 'nav-link': true, active: $route.name == 'LeadsSettings' }" :to="{ name: 'LeadsSettings' }"> Settings </router-link>
        </li>
        <li v-if="tokenCan('admin')" class="nav-item">
          <router-link :class="{ 'nav-link': true, active: $route.name == 'Reclaims' }" :to="{ name: 'Reclaims' }"> Reclaims </router-link>
        </li>
      </ul>
    </div>
    <div>
      <Leads v-if="$route.name != 'Reclaims' && $route.name != 'LeadsSettings'" :status-id="selectedStatus.id" :show-archived="showArchived" />
      <LeadsSettings v-if="$route.name == 'LeadsSettings'" />
      <Reclaims v-if="$route.name == 'Reclaims'" />
    </div>
  </div>
</template>

<script>
import { tabActive } from '@/mixins/methods';
import permissionsMixin from '/src/mixins/permissions';
import Leads from './Leads';
import Reclaims from '@/components/pages/leads/Reclaims';
import LeadsSettings from '@/components/pages/leads/LeadsSettings';

const leadStatuses = [
  { title: 'Leads', id: 1 },
  { title: 'Future', id: 4 },
  { title: 'Follow-Ups', id: 6 },
  { title: 'Cancelled', id: 5 },
];

export default {
  name: 'LeadsTabs',
  mixins: [tabActive, permissionsMixin],
  components: { Leads, Reclaims, LeadsSettings },
  props: {
    showArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statuses: leadStatuses,
    };
  },
  computed: {
    selectedStatus() {
      const { status } = this.$route.params;
      if (status) {
        return this.statuses.find(s => s.id == status);
      } else {
        return this.statuses[0];
      }
    },
  },
  methods: {
    isActive(status) {
      return !this.showArchived && status.id == this.selectedStatus.id && this.$route.name != 'Reclaims' && this.$route.name != 'LeadsSettings';
    },
  },
};
</script>
