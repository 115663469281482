<template>
  <tbody>
    <tr>
      <td class="align-middle">{{ dispatcher.name }}</td>
      <td class="align-middle">
        <a :href="'tel:' + dispatcher.phone">{{ dispatcher.phone | formatPhone}}</a>
      </td>
      <td>
          <button class="btn btn-sm float-right" :class="{'btn-primary': open, 'btn-outline-primary': !open, 'invisible': editMode}" @click="open = !open">
            {{ open ? 'Less' : 'More' }}
          </button>
      </td>
    </tr>
    <transition name="fade">
      <tr v-if="open" class="bg-light">
        <td colspan="3">
          <div v-if="!editMode" class="p-3 d-flex flex-column" style="gap: 20px;">
            <div class="d-flex flex-column" style="gap: 20px;">
              <div class="d-flex" style="gap: 20px;">
                <div style="width: 64px;"><b>Name</b></div>
                <div>{{ dispatcher.name }}</div>
              </div>
              <div class="d-flex" style="gap: 20px;">
                <div style="width: 64px;"><b>Number</b></div>
                <div><a :href="'tel:' + dispatcher.phone">{{ dispatcher.phone | formatPhone}}</a></div>
              </div>
            </div>
            <div>
              <button class="btn btn-outline-primary btn-sm" @click="editMode = true"><i class="fas fa-edit"></i> Edit</button>
              <button class="btn btn-outline-danger btn-sm ml-2" @click="$emit('delete')"><i class="fas fa-trash"></i> Delete</button>
            </div>
          </div>

          <div v-else>
            <DispatcherForm :dispatcher="editableDispatcher" :is-editing="true" @save="val => saveChanges(val)" @cancel="cancelEdit" />
          </div>
        </td>
      </tr>
    </transition>
  </tbody>
</template>

<script>
import DispatcherForm from './DispatcherForm.vue';
export default {
  name: 'DispatcherRow',
  components: { DispatcherForm },
  props: {
    dispatcher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      editMode: false,
      editableDispatcher: { ...this.dispatcher },
    };
  },
  methods: {
    saveChanges(val) {
      this.$emit('update', val);
      this.editMode = false;
    },
    cancelEdit() {
      this.editableDispatcher = { ...this.dispatcher };
      this.editMode = false;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
