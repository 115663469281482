<template>
  <div class="wrapper">
    <button @click="toggle" :class="toggleClass" ref="toggle">{{ label }}</button>
    <div v-if="isOpened" class="menu bg-lighter" :style="`top: ${menuTopDistance}px`">
      <div class="card">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    label: {
      type: String,
      default: '',
    },
    toggleClass: {
      type: String,
      default: 'btn btn-secondary dropdown-toggle',
    },
  },
  data() {
    return {
      isOpened: false,
      menuTopDistance: 40,
    };
  },
  methods: {
    toggle() {
      this.isOpened ? this.close() : this.open();
    },
    open() {
      this.isOpened = true;
      document.addEventListener('click', this.handleClickOutside);
    },
    close() {
      this.isOpened = false;
      document.removeEventListener('click', this.handleClickOutside);
    },
    handleClickOutside(e) {
      // If user clicks on anything outside of the menu - close it.
      if (!this.$el.contains(e.target)) {
        this.close();
      }
    },
    handleClickInside(e) {
      // If user click on certain elements within the menu, we might want to close it
      // This is done by adding `data-close-dropdown` attribute to such elements.
      if (e.target.hasAttribute('data-close-dropdown')) {
        this.close();
      }
    },
  },
  mounted() {
    this.$el.addEventListener('click', this.handleClickInside);
    const menuPadding = 4;
    this.menuTopDistance = this.$refs.toggle.offsetHeight + menuPadding;
  },
  beforeDestroy() {
    this.$el.removeEventListener('click', this.handleClickInside);
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

.menu {
  position: absolute;
  left: 0;
  z-index: 1000;
  border: 0px solid rgba(24, 28, 33, 0.15);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
  border-radius: 0.125rem;
}

.btn-last {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
