<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Customers</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="d-flex align-items-center">
            <SearchInput v-model="search" placeholder="Search..." @input="startSearch"/>
          </div>
        </div>

        <LoadingIndicator centered v-if="loading" />
        <table class="table" v-if="!loading && customers && customers.length > 0">
          <thead class="table">
            <tr>
              <TableSortableHeader class="px-1" label="Id" field="id" />
              <TableSortableHeader class="px-1" label="Leads" field="leads_count" />
              <TableSortableHeader class="px-1" label="Company" field="company" />
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Contact</th>
              <th scope="col">Phone</th>
              <TableSortableHeader class="px-1" label="Email" field="email" />
              <th scope="col">Last Order</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers" :key="customer.id">
              <td>
                <router-link :to="{ name: 'CustomerDetails', params: { id: customer.id } }">#{{ customer.id }}</router-link>
              </td>
              <td>{{ customer.leadsCount }}</td>
              <td>{{ customer.contact.company }}</td>
              <td>{{ customer.contact.firstName }}</td>
              <td>{{ customer.contact.lastName }}</td>
              <td>{{ customer.contact.contact }}</td>
              <td>
                <div v-if="customer.contact.phone">
                  <a :href="`tel:${customer.contact.phone}`">{{ customer.contact.phone }}</a>
                </div>
                <div v-if="customer.contact.phone2">
                  <a :href="`tel:${customer.contact.phone2}`">{{ customer.contact.phone2 }}</a>
                </div>
                <div v-if="customer.contact.cellPhone">
                  <a :href="`tel:${customer.contact.cellPhone}`">{{ customer.contact.cellPhone }}</a>
                </div>
              </td>
              <td>
                <a :href="`mailto:${customer.contact.email}`">{{ customer.contact.email }}</a>
              </td>
              <td>
                <router-link v-if="customer.lastOrder" :to="{ name: 'OrderDetails', params: { id: customer.lastOrder.id } }">{{ customer.lastOrder.signedAt  | formatDate}}</router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <EmptyState v-if="!loading && !customers" message="No customers found matching search criteria" />

        <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="updatePageUrlParam" :is-disabled="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import statesMixin from '@/mixins/states';
import pageLinksMixin from '@/mixins/pageLinks';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import EmptyState from '@/components/shared/EmptyState';
import TableSortableHeader from '@/components/shared/TableSortableHeader';
import SearchInput from '@/components/shared/SearchInput.vue'
import { debounce } from 'debounce';

const updateSearchUrlParamDebounced = debounce(function (router, search) {
  router.push({ query: { search } });
}, 800);

export default {
  name: 'CustomerList',
  mixins: [pageLinksMixin, statesMixin],
  components: { LoadingIndicator, EmptyState, TableSortableHeader, SearchInput },
  data() {
    return {
      customers: [],
      loading: false,
      search: this.$route.query.search || '',
    };
  },
  computed: {
    hasNextPage() {
      return this.customers.length === this.perPage;
    },
    fetchParams() {
      const params = {};
      const { sort, search } = this.$route.query;
      if (search) params.search = search;
      if (sort) params.sort = sort;
      params.page = this.currentPage;
      params.perPage = this.perPage;
      return params;
    },
  },
  methods: {
    updateRouteQuery(newQuery) {
      const oldQuery = this.$route.query;
      const query = Object.assign({}, oldQuery, newQuery);
      this.$router.push({ query });
    },
    async fetch() {
      if (this.loading) return;
      this.loading = true;
      const response = await ApiClient.customers.get({ params: this.fetchParams });
      this.loading = false;
      if (response.status == 200) {
        this.customers = response.data.data;
      }
    },
    startSearch(value) {
      this.search = value
      if (this.$route.query.search != this.search) {
        updateSearchUrlParamDebounced(this.$router, this.search);
      }
    },
  },
  watch: {
    fetchParams() {
      this.fetch();
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped></style>
