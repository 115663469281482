<template>
  <form @submit.prevent="save()" class="p-3 d-flex flex-column" style="gap:20px;">
    <div v-if="!isEditing" class="header"> Add New Dispatcher</div>

    <ValidatedInput
      :label="'Name'"
      :id="'displatcherName'+localDispatcher.id"
      :value="localDispatcher.name"
      @input="newValue => localDispatcher.name = newValue"
      class="font-weight-bold  mb-0"
      :required="true"
    />

    <ValidatedInput
      :label="'Number'"
      :id="'displatcherPhone'+localDispatcher.id"
      mask="phone"
      :value="localDispatcher.phone"
      @input="newValue => localDispatcher.phone = newValue"
      class="font-weight-bold mb-0"
      :required="true"
    />

    <div class="d-flex" style="gap:8px;">
      <button class="btn btn-outline-primary" @click="$emit('cancel')">Cancel</button>
      <button class="btn btn-primary" type="submit">Save</button>
    </div>
  </form>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';

export default {
  name: 'DispatcherForm',
  components: {
    ValidatedInput,
  },
  props: {
    dispatcher: {
      type: Object,
      default: () => ({ name: '', phone: '' }),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localDispatcher: { ...this.dispatcher },
    };
  },
  methods: {
    save() {
      this.$emit('save', {
        name: this.localDispatcher.name,
        phone: this.localDispatcher.phone,
      });
    },
  },
};
</script>

<style scoped>
.header {
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 0%;
}
</style>
