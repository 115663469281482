<template>
  <!-- <Sidenav class="sidenav sidenav-vertical bg-white layout-sidenav">
    <div class="sidenav-inner"> -->
  <div class="sidenav sidenav-vertical bg-white sidenav--sticky-container nexus-nav">
    <div class="sticky">
      <SidenavRouterLink icon="fas fa-home" to="/" :exact="true">Home</SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('reports')" icon="fas fa-chart-line" to="/reports" :active="isMenuActive('/reports')"> Reports </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('competition')" icon="fas fa-shipping-fast" :active="isMenuActive('/competition')" to="/competition"> Competition </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('market-rates')" icon="fas fa-balance-scale" :active="isMenuActive('/market-rates')" to="/market-rates"> Market Rates </SidenavRouterLink>
      <div class="sidenav-divider mt-0"></div>
      <SidenavRouterLink v-if="tokenCan('leads')" icon="fas fa-car-side" :active="isMenuActive('/leads')" to="/leads"> Leads </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('quotes')" icon="fas fa-hands-helping" :active="isMenuActive('/quotes')" to="/quotes"> Quotes </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('orders')" icon="fas fa-truck-moving" :active="isMenuActive('/orders')" to="/orders"> Orders </SidenavRouterLink>
      <div class="sidenav-divider mt-0"></div>
      <SidenavRouterLink v-if="tokenCan('carriers')" icon="fas fa-truck-loading" to="/carriers" :active="isMenuActive('/carriers')"> Carriers </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('customers')" icon="fas fa-users" to="/customers" :active="isMenuActive('/customers')"> Customers </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('sources')" icon="fas fa-map-signs" to="/sources" :active="isMenuActive('/sources')"> Sources </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('vehicles')" icon="fas fa-car-side" to="/vehicles/types" :active="isMenuActive('/vehicles')"> Vehicles </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('locations')" icon="fas fa-map-marker-alt" to="/locations/states" :active="isMenuActive('/locations')"> Locations </SidenavRouterLink>
      <div v-if="tokenCan('admin')" class="sidenav-divider mt-0"></div>
      <SidenavRouterLink v-if="tokenCan('users')" icon="fas fa-user" to="/users" :active="isMenuActive('/users')"> Users </SidenavRouterLink>
      <SidenavRouterLink v-if="tokenCan('admin')" icon="ion ion-md-notifications-outline" to="/notifications/custom" :active="isMenuActive('/custom-notification')">
        Notification
      </SidenavRouterLink>
      <!--  <SidenavRouterLink v-if="tokenCan('preferences')" icon="far fa-sun" to="/preferences/settings" :active="isMenuActive('/preferences')"> Preferences </SidenavRouterLink> -->
    </div>
  </div>
  <!-- </div>
  </Sidenav> -->
</template>

<script>
import permissionsMixin from '/src/mixins/permissions';
import { SidenavRouterLink } from '@/vendor/libs/sidenav';

export default {
  name: 'LayoutSidenav',
  components: {
    SidenavRouterLink,
  },
  mixins: [permissionsMixin],
  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidenav--sticky-container {
  display: block;
  overflow: visible;
  padding-top: 8px;
}

.sticky {
  position: sticky;
  top: 0;
}

.nexus-nav {
  width: 12rem;
}
</style>
