import { format, parseISO, formatDistanceToNow } from 'date-fns';

function parseAndFormatDate(date, pattern) {
  if (date) {
    return format(parseISO(date), pattern);
  } else {
    return 'Not set';
  }
}

export default {
  install(Vue) {
    Vue.filter('capitalize', text => {
      return text
        .split(' ')
        .map(item => `${item.charAt(0).toUpperCase() + item.slice(1)}`)
        .join(' ');
    });
    Vue.filter('formatDate', date => {
      return parseAndFormatDate(date, 'do MMM yyyy');
    });
    //formats time relative to the current moment (e.g., "1m ago", "5m ago", or a full date if it's older)
    Vue.filter('formatRelativeTime', date => {
      if (!date) return 'Not set';

      const parsedDate = parseISO(date);
      const diffInMinutes = Math.abs((new Date() - parsedDate) / (1000 * 60));

      if (diffInMinutes < 60 * 24) {
        return formatDistanceToNow(parsedDate, { addSuffix: true });
      } else {
        return format(parsedDate, "MM/dd/yyyy hh:mm a");
      }
    });
    Vue.filter('formatDateTime', date => {
      return parseAndFormatDate(date, "MMM do yyyy 'at' HH:mm");
    });
    Vue.filter('formatMoney', value => {
      return value ? '$' + value : '-';
    });
    Vue.filter('formatPhone', value => {
      if (!value) return value;

      let m = value.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (m) {
        return (m[1] ? '+1 ' : '') + '(' + m[2] + ') ' + m[3] + '-' + m[4];
      }
      return value;
    });
  },
};
