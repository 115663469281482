<template>
  <div class="mb-3 p-3 bg-light d-flex flex-column" style="gap: 20px;">
    <div class="header" v-if="!isEditing">Add New Driver</div>

    <form @submit.prevent="save()" class="d-flex flex-column" style="gap: 20px;">

      <ValidatedInput
        :label="'Name'"
        :id="'driverName'+localDriver.id"
        :value="localDriver.name"
        @input="newValue => localDriver.name = newValue"
        class="font-weight-bold  mb-0"
        :required="true"
      />
      <ValidatedInput
        :label="'Number'"
        :id="'driverPhone'+localDriver.id"
        mask="phone"
        :value="localDriver.phone"
        @input="newValue => localDriver.phone = newValue"
        class="font-weight-bold"
        :required="true"
      />

      <div class="d-flex flex-column" style="gap: 8px;">
        <div><b>Licence Images</b></div>
        <div v-if="licenseImages.length" style="margin-bottom: 10px;position: relative; display: flex; gap: 4px; flex-wrap: wrap;">
          <div v-for="(image, index) in licenseImages" :key="index">
            <ImageCard v-if="!image.toDelete" :url="image.id ? (public_url + image.url) : image.url" :edit="true" @delete="removeLicenseImage(index)"/>
          </div>
        </div>
        <label>
          <input id="licence" ref="licence" type="file" class="form-control mb-2" @change="uploadLicense" />
        </label>
      </div>

      <div class="d-flex flex-column" style="gap: 8px;">
        <div><b>Truck Images</b></div>

        <div v-if="truckImages.length"  style="margin-bottom: 10px;position: relative; display: flex; gap: 4px; flex-wrap: wrap;">
          <div v-for="(image, index) in truckImages" :key="index">
            <ImageCard v-if="!image.toDelete" :url="image.id? (public_url + image.url) : image.url" :edit="true" @delete="removeTruckImage(index)"/>
          </div>
        </div>
        <label>
          <input id="truck" ref="truck" type="file" class="form-control mb-2" @change="uploadTruck" />
        </label>
      </div>

      <div class="d-flex" style="gap: 8px;">
        <button class="btn btn-outline-primary" @click="$emit('cancel')">Cancel</button>
        <button class="btn btn-primary" :disabled="isUploading">
          <span v-if="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import ImageCard from './ImageCard.vue';
import ValidatedInput from '@/components/shared/ValidatedInput';

export default {
  name: 'DriverForm',
  components: {
    ImageCard,
    ValidatedInput
  },
  props: {
    driver: {
      type: Object,
      default: () => ({ name: '', phone: ''}),
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    carrierId: {
      type: Number,
      required: true,
    },
    licenses:{
      type: Array,
      default: () => [],
      required: false,
    },
    trucks:{
      type: Array ,
      default: () => [],
      required: false,
    },
    public_url: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      publicUrl : this.publicUrl,
      localDriver: { ...this.driver },
      licenseImages: this.licenses,
      truckImages: this.trucks,
      isUploading: false
    };
  },
  methods: {
    async save() {
      if(this.isUploading) return;

      this.isUploading = true;

      let apiResponse;

      const request = this.isEditing
        ? ApiClient.carriers.drivers.put({
            carrierId: this.carrierId,
            driverId: this.localDriver.id,
            body: {
              name: this.localDriver.name,
              phone: this.localDriver.phone,
            }
          })
        : ApiClient.carriers.drivers.post({
            carrierId: this.carrierId,
            body: {
              name: this.localDriver.name,
              phone: this.localDriver.phone,
            }
          });

      await request
        .then((response) => {
          apiResponse = response;
          const driverId = apiResponse.data.data.id;

          return Promise.all([
            this.uploadLicenseImages(driverId),
            this.uploadTruckImages(driverId)
          ]);
        })
        .then(() => {
          this.isUploading = false;
          this.$emit('save', apiResponse.data.data);
        })
        .catch((error) => {
          console.error("Error:", error);
          this.isUploading = false;
        });
    },
    uploadLicenseImages(driverId) {
      const uploadPromises = this.licenseImages.map((image) => {
        if (image.id && image.toDelete === true) {
          return ApiClient.carriers.drivers.delete_license({
            carrierId: this.carrierId,
            driverId: driverId,
            licenseId: image.id,
          });
        } else if (image.id === undefined) {
          const formData = new FormData();
          formData.append('file', image.file);

          return ApiClient.carriers.drivers.post_licenses({
            carrierId: this.carrierId,
            driverId: driverId,
            body: formData,
          });
        }
      });

      return Promise.all(uploadPromises);
    },
    uploadTruckImages(driverId) {
      const uploadPromises = this.truckImages.map((image) => {
        if (image.id && image.toDelete === true) {
          return ApiClient.carriers.drivers.delete_truck({
            carrierId: this.carrierId,
            driverId: driverId,
            truckId: image.id,
          });
        } else if (image.id === undefined) {
          const formData = new FormData();
          formData.append('file', image.file);

          return ApiClient.carriers.drivers.post_trucks({
            carrierId: this.carrierId,
            driverId: driverId,
            body: formData,
          });
        }
      });

      return Promise.all(uploadPromises);
    },

    uploadLicense(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.licenseImages.push({ url: e.target.result, file: file });
        };
        reader.readAsDataURL(file);

        this.$refs.licence.value = null;
      }
    },
    uploadTruck(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.truckImages.push({ url: e.target.result, file: file });
        };
        reader.readAsDataURL(file);
        this.$refs.truck.value = null;
      }
    },
    removeLicenseImage(index) {
      const image = this.licenseImages[index];
      if (image.id) {
        this.$set(image, 'toDelete', true);
      } else {
        this.licenseImages.splice(index, 1);
      }
    },
    removeTruckImage(index) {
      const image = this.truckImages[index];
      if (image.id) {
        this.$set(image, 'toDelete', true);
      } else {
        this.truckImages.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.header {
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 0%;
}

</style>
