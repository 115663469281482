<template>
  <div>
    <LabeledData v-if="contact.firstName" label="Full name" :value="contact.firstName + ' ' + contact.lastName" />
    <LabeledData v-if="contact.company" label="Company" :value="contact.company" :internalLink="carrierProfile" />
    <LabeledData v-if="contact.phone" label="Phone" :value="contact.phone | formatPhone" :externalLink="'tel:' + contact.phone"/>
    <LabeledData v-if="contact.phone2" label="Secondary phone" :value="contact.phone2 | formatPhone" :externalLink="'tel:' + contact.phone2"/>
    <LabeledData v-if="contact.email" label="Email" :value="contact.email" :externalLink="'mailto:' + contact.email" />
    <LabeledData v-if="contact.cellPhone" label="Cell phone" :value="contact.cellPhone | formatPhone" :externalLink="'tel:' + contact.phone2"/>
  </div>
</template>

<script>
import LabeledData from './LabeledData';

export default {
  name: 'ContactData',
  components: {
    LabeledData,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    carrierId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    carrierProfile() {
      return this.carrierId ? { name: 'CarrierDetails', params: { id: this.carrierId } } : null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
