<template>
  <div class="lead-card-edit outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1" @click="closeModal">
      <div :class="{ 'modal-dialog modal-dialog-centered': true, ' modal-xl': showEdit }" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Lead #{{ lead.id }}</h5>
            <button @click="closeModal" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">×</button>
          </div>
          <div class="modal-body bg-light">
            <b-tabs class="nav-tabs-top mb-4" v-if="showEdit">
              <b-tab title="Edit" v-if="canEdit" active>
                <div class="p-0 bg-light">
                  <div class="justify-content-end mb-2 d-none">
                    <button class="btn btn-sm btn-warning" href="#" @click="convertToQuote">Convert to quote</button>
                    <button class="btn btn-sm btn-danger ml-1" href="#" @click="archive">Cancel Lead</button>
                  </div>
                  <FormFieldsLead :form-fields="form" :is-lead-rules="true" :render-shipping-form="true" :errors="errors" @input="handleFormInput" />
                  <button @click="save()" class="btn btn-primary">Save</button>
                  <button @click="saveandConvert()" class="btn btn-primary ml-3" v-if="lead.userId == user.id">Save and Convert to Order</button>

                  <b-tabs class="nav-tabs-top mb-4 mt-5">
                    <b-tab title="Notes" active>
                      <div class="card-body">
                        <NotesList :leadlike-id="form.id" :read-only="false" />
                      </div>
                    </b-tab>
                    <b-tab title="Tasks" lazy ref="tasksTab">
                      <div class="card-body">
                        <TasksList
                          :leadlike-id="form.id"
                          @tasksDue="x => {form.tasksDue = x;}"
                        />
                      </div>
                    </b-tab>
                    <b-tab title="Emails" lazy>
                      <div class="card-body">
                        <EmailsList :leadlike-id="form.id" />
                      </div>
                    </b-tab>
                    <b-tab title="SMS" lazy>
                      <div class="card-body">
                        <SmsList :leadlike-id="form.id" />
                      </div>
                    </b-tab>
                  </b-tabs>

                </div>
              </b-tab>
              <b-tab title="Rates" v-if="canEdit" lazy>
                <div class="card-body p-0">
                  <MarketRatesList :lead-quote-order="form" :errors="errors" />
                </div>
              </b-tab>
              <b-tab title="Offers" v-if="canEdit" lazy>
                <div class="card-body p-0">
                  <OrderOffers :order="form" :errors="errors" />
                </div>
              </b-tab>
              <b-tab title="Loadboards" lazy>
                <div class="card-body p-0">
                  <OrderLoadboards :order="form" :errors="errors" />
                </div>
              </b-tab>
              <b-tab title="History" v-if="canEdit" lazy>
                <div class="card-body p-0">
                  <LeadlikeHistory :lead-quote-order="form" :errors="errors" />
                </div>
              </b-tab>
            </b-tabs>
            <div v-else>
              <LoadingIndicator v-if="checking" centered />
              <div v-else>
                <div class="form-group">
                  <ValidatedInput id="formPriceOffered" rules="required|min:2" v-model="leadFormPrice" mask="price" label="Price offered" />
                </div>
                <div class="form-group">
                  <button @click="savePriceForm()" class="btn btn-primary">Save and Convert to Order</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FormFieldsLead from '@/components/shared/formFields/FormFieldsLead';
import OrderOffers from '@/components/shared/OrderOffers';
import OrderLoadboards from '@/components/shared/OrderLoadboards';
import LeadlikeHistory from '@/components/shared/LeadlikeHistory';
import { ApiClient } from '@/api/ApiClient';
import MarketRatesList from '@/components/shared/MarketRatesList';
import ValidatedInput from '@/components/shared/ValidatedInput';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import NotesList from '@/components/shared/NotesList';
import EmailsList from '@/components/shared/EmailsList';
import SmsList from '@/components/shared/SmsList';
import TasksList from '@/components/shared/TasksList';

export default {
  name: 'LeadCardEdit',
  components: {
    FormFieldsLead,
    OrderOffers,
    OrderLoadboards,
    LeadlikeHistory,
    MarketRatesList,
    ValidatedInput,
    LoadingIndicator,
    NotesList,
    EmailsList,
    SmsList,
    TasksList
  },
  data() {
    return {
      errors: {},
      form: {},
      isOrderable: false,
      leadFormPrice: null,
      checking: true,
      showEdit: true,
    };
  },
  props: {
    leadId: {
      type: Number,
      required: true,
    },
    forceConvert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      lead(state) {
        return state.leads.items.find(lead => lead.id === this.leadId);
      },
      user: state => state.auth.user,
    }),
    priceEntered() {
      if (this.form.vehicles[0].price == null || this.form.vehicles[0].price == '0' || this.form.vehicles[0].price == 'undefined' || this.form.vehicles[0].price == '') {
        return false;
      }
      return true;
    },
    canEdit() {
      return this.$store.getters['auth/isMyUser'](this.form.userId) || this.$store.getters['auth/tokenCan']('leads:edit') || (this.form.leadReclaimsCount > 0);
    },
  },
  methods: {
    handleFormInput(newForm) {
      this.form = newForm;
      if (this.form.priority == 1 && this.form.vehicles[0].price) {
        this.isOrderable = true;
      }
    },
    closeModal(){
      if (JSON.stringify(this.lead) !== JSON.stringify(this.form)) {
        const answer = window.confirm('Do you really want to leave? There might be unsaved changes!');
        if (!answer) return false;
      }
      this.$emit('close');
    },
    async save() {
      const updatedAttributes = this.form;
      const response = await this.$store.dispatch('leads/update', {
        leadId: this.leadId,
        updatedAttributes,
      });
      if (response.status === 200) {
        this.$emit('close');
        this.errors = {};
      } else if (response.status === 422) {
        this.errors = response.data.errors;
      }
    },
    async saveandConvert() {
      if (this.priceEntered) {
        const updatedAttributes = this.form;
        const response = await this.$store.dispatch('leads/update', {
          leadId: this.leadId,
          updatedAttributes,
        });
        if (response.status === 200) {
          this.convertToOrder();
          this.$emit('close');
          this.errors = {};
        } else if (response.status === 422) {
          this.errors = response.data.errors;
        }
      } else {
        this.checking = false;
        this.showEdit = false;
      }
    },
    async savePriceForm() {
      this.form.vehicles[0].price = this.leadFormPrice;
      this.saveandConvert();
    },
    async convertToOrder() {
      const { id } = this.lead;
      const response = await ApiClient.leads.promote({ id, body: { leadTypeId: 3 } });
      if (response.status === 200) {
        this.$router.push({ name: 'OrderDetails', params: { id } });
      }
    },
    async convertToQuote() {
      const { id } = this.lead;
      const response = await ApiClient.leads.promote({ id, body: { leadTypeId: 2 } });
      if (response.status === 200) {
        this.$router.push({ name: 'QuoteDetails', params: { id } });
      }
    },
    async archive() {
      const { id } = this.lead;
      const response = await ApiClient.leads.delete({ id });
      if (response.status === 200) {
        this.$router.push({ name: 'LeadsArchived' });
      }
    },
    closePriceDD() {
      this.$refs.dropdownPrice.hide(true);
    },
  },
  watch: {
    leadId: {
      immediate: true,
      handler() {
        this.form = Object.assign({}, this.lead);
        if (this.form.priority == 1 && this.form.vehicles[0].price) {
          this.isOrderable = true;
        }
      },
    },
  },
  mounted() {
    if (this.forceConvert) {
      this.showEdit = false;
      this.saveandConvert();
    }
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  border: none;
}
h5 {
  margin: 0;
}

.outter-modal {
  position: absolute;
  z-index: 1089;
}

</style>

<style lang="scss">
.no-price-dd {
  .dropdown-menu {
    min-width: 20rem;
  }
}
</style>
