<template>
  <div>

    <div class="d-flex justify-content-between align-items-center">
      <h1>Users</h1>
      <router-link class="btn btn-primary btn-sm" :to="{ name: 'UserNew' }"> <span class="mr-1 fas fa-plus"></span> New user </router-link>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link :class="{ 'nav-link': true, active: !$route.query.archived }" :to="{ name: 'UserList' }"> Active </router-link>
      </li>
      <li class="nav-item">
        <router-link :class="{ 'nav-link': true, active: $route.query.archived }" :to="{ name: 'UserList', query: {archived:1} }"> Disabled </router-link>
      </li>
      <li class="nav-item">
        <router-link :class="{ 'nav-link': true, active: $route.name == 'RolePermissions' }" :to="{ name: 'RolePermissions' }"> Role Permissions </router-link>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
        <div>
          <div class="d-flex align-items-center">
            <div class="search-wrapper mr-3">
              <SearchInput v-model="search" @input="startSearch" placeholder="Search..."/>
            </div>
            <b-form-checkbox class="mb-0" v-if="search" v-model="searchArchived" @input="startSearch" :unchecked-value="0" :value="1">Include disabled</b-form-checkbox>
          </div>
          <div class="py-3">
            <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="updatePageUrlParam" :is-disabled="loading" />
          </div>
        </div>

        <div class="position-relative">
          <div v-if="loading" class="loading-overlay">
            <LoadingIndicator centered />
          </div>

          <table class="table">
            <thead class="table">
              <tr>
                <TableSortableHeader class="px-0 text-center" label="Id" field="id" />
                <th scope="col"></th>
                <TableSortableHeader class="px-0 text-center" label="Username" field="username" />
                <TableSortableHeader class="px-0 text-center" label="Email" field="email" />
                <TableSortableHeader class="px-0 text-center" label="Name" field="name" />
                <th scope="col">Phone</th>
                <th scope="col">Daily Limit</th>
                <th scope="col">Fresh Limit</th>
                <th scope="col">Roles</th>
                <th scope="col">Last Login</th>
                <TableSortableHeader class="px-0 text-center" label="Created At" field="createdAt" />
              </tr>
            </thead>
            <tbody :class="{ 'table-loading': loading }">
              <tr v-for="user in users" :key="user.id"  :class="rowClassName(user)">
                <td class="text-center"><b-link :to="{ name: 'UserDetails', params: { id: user.id } }">#{{ user.id }}</b-link></td>
                <td><Avatar :size="32" :photo="user.photo" /></td>
                <td>{{ user.username.replace('nexusautotransport.com','nexus..') }}</td>
                <td>{{ user.email.replace('nexusautotransport.com','nexus..') }}</td>
                <td>{{ user.name }} <CountryFlag v-if="user.country" :country="user.country" size="small"/></td>
                <td>{{ user.phone | formatPhone }}</td>
                <td>{{ user.dailyLimit }}</td>
                <td>{{ user.freshLimit }}</td>
                <td>{{ user.roles ? user.roles.map(x => x.name).join(', ') : '' }}</td>
                <td><div v-if="user.lastLogin">{{ user.lastLogin.createdAt | formatDate }}<br />{{ user.lastLogin.ip }}</div></td>
                <td>{{ user.createdAt | formatDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="updatePageUrlParam" :is-disabled="loading" />

      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import pageLinksMixin from '@/mixins/pageLinks';
import { debounce } from 'debounce';
import permissionsMixin from '/src/mixins/permissions';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import TableSortableHeader from '@/components/shared/TableSortableHeader';
import CountryFlag from 'vue-country-flag';
import SearchInput from '@/components/shared/SearchInput.vue'
import Avatar from '@/components/shared/avatar/Avatar';

const updateSearchUrlParamDebounced = debounce(function (router, search) {
  router.push({ query: search });
}, 800);

export default {
  components: {
    LoadingIndicator,
    TableSortableHeader,
    CountryFlag,
    Avatar,
    SearchInput
  },
  mixins: [pageLinksMixin, permissionsMixin],
  data() {
    return {
      users: [],
      loading: false,
      search: '',
      searchArchived: false,
    };
  },
  computed: {
    hasNextPage() {
      return this.users.length === this.perPage;
    },
    sortOrder() {
      const { sort } = this.$route.query;
      return sort;
    },
  },
  methods: {

    async fetch() {
      if (this.loading) return;
      this.loading = true;

      let opts = {
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
        searchArchived: this.searchArchived ? 1 : 0,
        sort: this.sortOrder,
      };

      if (this.$route.query.archived) opts.isArchived = 1;
      
      const response = await ApiClient.users.get({params: opts});
      this.loading = false;
      if (response.status == 200) {
        this.users = response.data.data;
        this.searchStats = response.data.search;
      }
    },
    startSearch() {
      updateSearchUrlParamDebounced(this.$router, { search: this.search, searchArchived: this.searchArchived });
    },

    rowClassName(user) {
      return user.deletedAt ? 'text-muted' : '';
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler: function () {
        const { search, searchArchived } = this.$route.query;
        this.search = search;
        this.searchArchived = searchArchived;
        this.fetch();
      },
    },
  },

  mounted() {
    const { search, searchArchived } = this.$route.query;
    this.search = search;
    this.searchArchived = searchArchived ? 1 : 0;
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  padding-left: 15px;
  padding-right: 15px;
}

.search-wrapper {
  max-width: 300px;
}
.select-wrapper {
  max-width: 300px;
}

.table-loading {
  opacity: 0.5;
}
.loading-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
}
/*
tbody td {
  padding: 5px;
}
*/
td {
  vertical-align: middle;
}

.text-muted a {
  color: inherit;
}

</style>
