<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <span class="header align-content-center">Drivers</span>
        <button class="btn btn-warning" :class="{ invisible: addNew }" @click="addNew = !addNew"><span class="fas fa-plus"></span> Add new</button>
      </div>
    </div>
    <div v-if="drivers.length" class="bg-light px-4 py-3">
      <SearchInput v-model="searchDrivers" placeholder="Search" />
    </div>

    <div class="card-body">
      <div v-if="addNew">
        <DriverForm  :carrierId="carrierId" :public_url="public_url" @cancel="addNew = false" @save="saveNew()" />
      </div>
      <table v-if="drivers.length" class="table table-sm mb-0">
        <thead>
          <tr>
            <TableSortableHeader label="Name" field="name"  class="border-0"/>
            <th scope="col" class="border-0">Number</th>
            <th scope="col" class="border-0"></th>
          </tr>
        </thead>
        <DriverRow v-for="driver in filteredDrivers" :key="driver.id" :carrierId="carrierId" :driver="driver" :public_url="public_url" @delete="deleteDriver(driver)" @updateDriver="(val) => updateDriver(val)"/>
      </table>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import TableSortableHeader from '@/components/shared/TableSortableHeader';
import SearchInput from '@/components/shared/SearchInput.vue';
import DriverForm from './DriverForm.vue';
import DriverRow from './DriverRow.vue';

export default {
  name: 'DriversCard',
  components: { TableSortableHeader, DriverRow, SearchInput, DriverForm },
  props: {
    carrierId: {
      type: Number,
      required: true,
    },
    public_url: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      searchDrivers: '',
      addNew: false,
      drivers: [],
    };
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      const apiResponse = await ApiClient.carriers.drivers.get({ carrierId: this.carrierId });
      this.drivers = apiResponse.data.data;
    },
    async deleteDriver(driver) {
      await ApiClient.carriers.drivers.delete({ carrierId: this.carrierId, driverId: driver.id });
      this.drivers = this.drivers.filter(x => x.id != driver.id);
    },
    async saveNew() {
      this.addNew = false;
      this.fetch();
    },
    updateDriver(newVal) {
      const index = this.drivers.findIndex(driver => driver.id === newVal.id);
      if (index !== -1) {
        this.drivers.splice(index, 1, newVal);
      }
    },
  },
  computed: {
    filteredDrivers() {
      return this.drivers.filter(driver => driver.name.toLowerCase().includes(this.searchDrivers.toLowerCase()));
    },
  },
};
</script>

<style scoped>
.header {
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 0%;
}
</style>
