<template>
  <div class="outter-modal">
    <div class="modal fade show" style="display: block; overflow: scroll" tabindex="-1" @click="onClose">
      <div class="modal-dialog modal-dialog-centered" @click.stop="">
        <div class="modal-content">
          <div class="modal-header">
            <button @click="onClose" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">×</button>
          </div>
          <div class="modal-body">
            <ValidatedSelect
              rules="required"
              label="Reason for marking red:"
              id="cancelReason"
              v-model="reason"
              :options="cancelReasons"
            />
            <label>Note:</label>
            <b-form-input placeholder="" v-model="noteInput" required></b-form-input>
            <div class="text-right">
              <b-button @click="markRed" type="button" class="mt-3" variant="primary" :disabled="!reason">Mark as Bad</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import ValidatedSelect from '@/components/shared/ValidatedSelect';
import cancelReasonsMixin from '@/mixins/cancelReasons';

export default {
  name: 'WhyRedModal',
  components: {
    ValidatedSelect,
  },
  mixins: [cancelReasonsMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    leadId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      noteInput: null,
      reason: null,
    };
  },
  methods: {
    async markRed() {
      //this.$store.dispatch('leads/updatePriority', { leadId: this.leadId, priority: 4 });
      //await ApiClient.leads.notes.post({ leadId: this.leadId, body: { note: this.noteInput } });
      await ApiClient.leads.cancel({ id: this.leadId, body: { cancelReasonId: this.reason, cancelReasonNote: this.noteInput } } );
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
h5 {
  margin: 0;
}

.outter-modal {
  position: absolute;
  z-index: 1089;
}
</style>
