<template>
  <b-card no-body class="rounded-lg leadcard border border-light">
    <LeadsCardHeader :can-edit="canEdit" :lead="lead" @handleBookedClick="$emit('handleBookedClick', $event)" @handleBadClick="$emit('handleBadClick', $event)" />

    <div class="content">
      <LeadsCardRoute :lead="lead" @click="$emit('route')" v-if="lead.userId" />
      <div :class="{ 'lead-columns': true, expand: showDetails }">
        <div>
          <LeadsCardData title="Name" @click.native="copyClipboard(customerContact.firstName + ' ' + customerContact.lastName)" :customer-name="true">
            <div>{{ customerContact.firstName }} {{ customerContact.lastName }}</div>
          </LeadsCardData>

          <span v-if="showDetails">
            <LeadsCardData title="Email" @click.native="copyClipboard(customerContact.email)"> {{ customerContact.email }} </LeadsCardData>
            <LeadsCardData title="Phone" @click.native="copyClipboard(maskPhone)"> {{ phoneLocation }} {{ phoneTimezone }} <span class="text-danger" v-if="phoneLocationState">({{phoneLocationState}})</span></LeadsCardData>
          </span>
        </div>
        <div>
          <LeadsCardData v-if="lead.userId || tokenCan('leads:move-date')" title="Move date">
            <LeadsCardMoveDate :move-date="lead.moveDate" />
            <span v-if="!lead.moveDate && lead.calculation">{{ lead.calculation.pickupTime }}</span>
          </LeadsCardData>

          <LeadsCardData v-if="lead.followups.length" title="Follow-up:">
            <div v-for="followup in lead.followups" :key="followup.id">
              {{ followup.followupAt | formatDateTime }}
            </div>
          </LeadsCardData>

          <span v-if="showDetails">
            <LeadsCardData title="Transport type"> {{ lead.transportType ? lead.transportType.title : '' }}</LeadsCardData>
            <LeadsCardData title="Created">
              {{ lead.sortOrder | moment('from', 'now') }}
            </LeadsCardData>
          </span>
        </div>
        <div>
          <LeadsCardData :title="getVehicleTitle">
            <div v-if="lead.vehicles.length > 4">
              <div @click="showVehicleList = true" class="multiple-vehicles">Multiple Vehicles</div>
              <VehicleListModal v-if="showVehicleList" :on-close="() => (showVehicleList = false)" :vehicles="lead.vehicles"></VehicleListModal>
            </div>
            <div v-else>
              <div v-for="vehicle in lead.vehicles" :key="vehicle.id">
                <LeadsCardVehicleMake :vehicle="vehicle" />
                <br />
              </div>
            </div>
          </LeadsCardData>
        </div>
        <div>
          <span class="nomb" v-if="lead.userId || tokenCan('leads:id') || $route.query.search">
            <LeadsCardPrice title="STD"> {{ lead.priceStd ? priceFormat(lead.priceStd) : '-' }} </LeadsCardPrice>
            <LeadsCardPrice title="EXP"> {{ lead.priceExp ? priceFormat(lead.priceExp) : '-' }} </LeadsCardPrice>
            <LeadsCardPrice title="ENC"> {{ lead.priceEnc ? priceFormat(lead.priceEnc) : '-' }} </LeadsCardPrice>
            <LeadsCardPrice title="CP" v-if="lead.priceNn"> {{ lead.priceNn ? priceFormat(lead.priceNn) : '-' }} </LeadsCardPrice>
            <LeadsCardPrice title="Price Offered" v-if="firstVehicle.price">{{ firstVehicle.price ? priceFormat(Math.round(totalPriceOffered)) : '-' }}</LeadsCardPrice>
          </span>
        </div>
      </div>

      <div class="mb-3" v-if="lead.userId">
        <button class="btn btn-secondary" @click="handleToggleDetails"><i class="mr-1 fas fa-info"></i> {{ showDetails ? 'Hide details' : 'Details' }}</button>
        <a @click.prevent="registerCall" @contextmenu.prevent="copyClipboard(maskPhone)" class="btn btn-primary pill" href="#"><i class="mr-1 fas fa-phone"></i> {{ maskPhone }}</a>

        <b-button-group v-if="isClaimed">
          <button class="btn btn-primary" @click="$emit('sms', '')"><i class="mr-1 fas fa-comments"></i> Text</button>
          <DropdownMenu toggle-class="dropdown-toggle btn btn-primary btn-last">
            <button class="dropdown-item" @click="$emit('sms', 'lead.text.price')" data-close-dropdown><i class="dropdown-icon fa-lg fas fa-comment-dollar"></i> Price Text</button>
            <button class="dropdown-item" @click="$emit('sms', 'lead.text.followup')" data-close-dropdown>
              <i class="dropdown-icon fa-lg fas fa-comment-dots"></i> Follow-Up Text
            </button>
            <button v-for="sms in smsTemplates" :key="sms.id" class="dropdown-item" @click="$emit('sms', sms.name)" data-close-dropdown>
              <i class="dropdown-icon fa-lg fas fa-envelope"></i> {{ sms.title }}
            </button>
            <button class="dropdown-item" @click="$emit('sms', 'lead.text.custom')" data-close-dropdown><i class="dropdown-icon fa-lg fas fa-envelope"></i> Custom Text</button>
          </DropdownMenu>
        </b-button-group>

        <b-button-group v-if="isClaimed">
          <button class="btn btn-primary" @click="$emit('email', '')"><i class="mr-1 fas fa-envelope"></i> Email</button>
          <DropdownMenu toggle-class="dropdown-toggle btn btn-primary btn-last">
            <button class="dropdown-item" @click="$emit('email', 'lead.email.price')" data-close-dropdown>
              <i class="dropdown-icon fa-lg fas fa-dollar-sign"></i> Price Email
            </button>
            <button v-for="email in emailTemplates" :key="email.id" class="dropdown-item" @click="$emit('email', email.name)" data-close-dropdown>
              <i class="dropdown-icon fa-lg fas fa-envelope"></i> {{ email.title }}
            </button>
            <button class="dropdown-item" @click="$emit('email', 'lead.email.custom')" data-close-dropdown><i class="dropdown-icon fa-lg fas fa-envelope"></i> Custom Email</button>
          </DropdownMenu>
        </b-button-group>

        <b-button-group v-if="isClaimed">
          <button class="btn btn-primary" @click="$emit('followup', '15m')"><i class="mr-1 fas fa-envelope"></i> Follow Up</button>
          <DropdownMenu toggle-class="dropdown-toggle btn btn-primary btn-last">
            <button class="dropdown-item" data-close-dropdown @click="$emit('followup', '15m')"><i class="dropdown-icon fa-lg fas fa-clock"></i> 15 mins</button>
            <button class="dropdown-item" data-close-dropdown @click="$emit('followup', '1h')"><i class="dropdown-icon fa-lg fas fa-clock"></i> 1 hour</button>
            <button class="dropdown-item" data-close-dropdown @click="$emit('followup', '1d')"><i class="dropdown-icon fa-lg fas fa-clock"></i> 1 day</button>
            <button class="dropdown-item" data-close-dropdown @click="$emit('followup', 'custom')"><i class="dropdown-icon fa-lg fas fa-clock"></i> Custom</button>
            <button v-if="lead.followups.length" class="dropdown-item" data-close-dropdown @click="$emit('followup', 'remove')"><i class="dropdown-icon fa-lg fas fa-clock"></i> Remove</button>
          </DropdownMenu>
        </b-button-group>

        <button class="btn btn-secondary" title="Edit Lead" @click="$emit('edit')">
          <i class="fas fa-pen"> </i>
        </button>

        <a class="btn btn-secondary" title="Landing Page" target="_blank" :href="`${randomLinkBase}${lead.uuid}/`">
          <i class="fas fa-globe-americas"></i>
        </a>
      </div>

      <div class="d-flex">
        <button class="btn btn-primary" title="Notes" @click="showNotes = !showNotes">
          <i class="fas fa-comments"></i>
        </button>
        <Mentionable
              :keys="['@']"
              :items="$store.state.users.mentionableUsers"
              offset="6"
            >
            
          <b-input-group class="ml-2">
            <b-input v-model="noteInput" @keydown.ctrl.13="addNote" placeholder="Add new note..." @focus="showNotes = true"></b-input>
            <template #append>
              <button class="btn btn-secondary" title="Send" size="sm" @click="addNote">
                <i class="mr-1 fas fa-paper-plane"></i>
              </button>
            </template>
          </b-input-group>

          <template #no-result>
              {{ $store.state.users.loadingMentionableUsers ? 'Loading...' : 'No result' }}
          </template>
        </Mentionable>
      </div>
      <div class="note-lists" v-if="showNotes == true">
        <NotesList v-if="showNotes == true" :from-lead="true" :leadlike-id="lead.id" ref="notelist" />
      </div>
    </div>
  </b-card>
</template>

<script>
import LeadsCardData from './LeadsCardData';
import LeadsCardPrice from './LeadsCardPrice';
import LeadsCardHeader from './LeadsCardHeader';
import LeadsCardMoveDate from './LeadsCardMoveDate';
import LeadsCardRoute from './LeadsCardRoute';
import LeadsCardVehicleMake from './LeadsCardVehicleMake';
import NotesList from '@/components/shared/NotesList';
import DropdownMenu from '@/components/shared/DropdownMenu';
import VehicleListModal from '@/components/shared/VehicleListModal';
import permissionsMixin from '/src/mixins/permissions';
import areaCodesMixin from '/src/mixins/areaCodes';
import { mapState } from 'vuex';
import { ApiClient } from '@/api/ApiClient';
import { Mentionable } from 'vue-mention'
import 'floating-vue/dist/style.css'

export default {
  components: {
    LeadsCardData,
    LeadsCardPrice,
    LeadsCardHeader,
    LeadsCardMoveDate,
    LeadsCardRoute,
    LeadsCardVehicleMake,
    NotesList,
    DropdownMenu,
    VehicleListModal,
    Mentionable
  },
  mixins: [permissionsMixin, areaCodesMixin],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    lead: {
      type: Object,
      default: () => ({}),
    },
    emailTemplates: {
      type: Array,
      default: () => [],
    },
    smsTemplates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDetails: false,
      showNotes: false,
      noteInput: '',
      showVehicleList: false,
    };
  },
  computed: {
    ...mapState({
      randomLinkBase: state => state.leads.randomLinkBase,
    }),
    followupAt(){
      if(!this.lead.followup) return null;
      return this.lead.followup.followupAt;
    },
    customerContact() {
      return this.lead.customer.contact;
    },
    firstVehicle() {
      return this.lead.vehicles[0] || {};
    },
    totalPriceOffered() {
      return this.lead.vehicles?.map(v => v.price).reduce((total, p) => total + (parseFloat(p) || 0), 0) || 0;
    },
    getVehicleTitle() {
      if (this.lead.vehicles.length > 1) {
        return 'Vehicles';
      }
      return 'Vehicle';
    },
    phone() {
      let phone = this.lead?.customer?.contact?.phone ?? null;
      return phone.length == 10 ? '+1' + phone : phone;
    },
    maskPhone() {
      if (!this.phone) return '';
      var fullPhone = this.phone.replace('+1', '');
      const phone = fullPhone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
      if (!phone) return fullPhone;
      return '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
    },
    phoneLocation() {
      if (!this.phone) return '';
      var fullPhone = this.phone.replace('+1', '');
      const phone = fullPhone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
      if (!phone) return fullPhone;
      let state  = this.areaCodeState(phone[1])?.toUpperCase();
      if(state != this.lead.pickupLocation.state?.toUpperCase() && state != this.lead.deliveryLocation.state?.toUpperCase()){
        return '(*' + phone[1] + '*) ' + phone[2] + '-' + phone[3];
      }
      return '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
    },
    phoneLocationState() {
      if (!this.phone) return null;
      var fullPhone = this.phone.replace('+1', '');
      const phone = fullPhone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
      if (!phone) return null;
      let state  = this.areaCodeState(phone[1])?.toUpperCase();
      if(state != this.lead.pickupLocation.state?.toUpperCase() && state != this.lead.deliveryLocation.state?.toUpperCase()){
        return state;
      }
      return null;
    },        
    phoneTimezone() {
      if (!this.phone) return '';
      var fullPhone = this.phone.replace('+1', '');
      const phone = fullPhone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
      if (!phone) return fullPhone;

      return this.areaCodeTimzone(phone[1]);
    },
    isClaimed() {
      return this.$store.getters['auth/isMyUser'](this.lead.userId) || this.lead.leadReclaimsCount ? true : false;
    },
  },
  methods: {
    async registerCall() {
      await ApiClient.leads.call({ id: this.lead.id });
      //window.open('tel://'+this.phone, '_self');
      window.location.href = 'tel://'+this.phone;
    },
    handleToggleDetails() {
      this.showDetails = !this.showDetails;
      this.showNotes = this.showDetails;
    },
    async addNote() {
      if (!this.noteInput.length) return;
      await ApiClient.leads.notes.post({ leadId: this.lead.id, body: { note: this.noteInput } });
      this.noteInput = '';
      this.$refs.notelist.fetch();
    },
    copyClipboard(copy) {
      navigator.clipboard.writeText(copy);
    },
    priceFormat(price) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
      return formatter.format(price);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 14px;
}

.lead-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
.note-lists {
  position: absolute;
  border: 1px solid #00000047;
  box-shadow: 0 20px 15px 11px #0000002e;
  width: calc(100% + 2px);
  margin-left: -1px;
  top: 97%;
  border-radius: 6px;
  left: 0;
  z-index: 9;
}
.leadcard {
  margin-top: 26px;
  box-shadow: 0 0 5px 0 #00000066;
  position: relative;
  .lead-columns {
    &.expand {
      height: unset;
    }
    /*height: 105px;*/
  }
  .nomb {
    div {
      margin-bottom: 5px !important;
    }
  }
}
.dropdown-icon {
  margin-right: 0.25rem;
  pointer-events: none;
}
.multiple-vehicles {
  cursor: pointer;
  color: #1e70cd;
}
</style>

<style lang="scss">
.mention-item {
  padding: 10px 10px;
  border-radius: 4px;
  min-width:100px;
}

.mention-selected {
  background: #efefef;
}
</style>