<template>
  <div class="d-flex justify-content-between align-items-center">
    <div  class="d-flex align-items-center bg-light rounded">
      <label style="width: 130px;" class="mb-0 px-2">Per page</label>
      <b-form-select size="sm" :value="perPage" @change="handlePerPageChange" :options="[20, 50, 100, 250, 500]" />
    </div>

    <div class="d-flex align-items-center bg-light rounded">
      <b-btn size="sm" class="bg-secondary" :disabled="currentPage <= 1 || isDisabled" @click="onPageChange(currentPage - 1)">
        <span class="fas fa-angle-left"></span>
      </b-btn>

      <div class="d-flex px-2">
        <label class="d-flex align-items-center mb-0 px-2">Page</label>
        <b-form-input size="sm" :disabled="isDisabled" v-model="pageInput" @keyup.enter="handleInputChange" @blur="handleInputChange" class="page"/>
      </div>

      <b-btn size="sm" class="bg-secondary" :disabled="!hasNextPage || isDisabled" @click="onPageChange(currentPage + 1)">
        <span class="fas fa-angle-right"></span>
      </b-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageInput: this.currentPage,
    };
  },
  computed: {
    ...mapState({
      perPage: state => state.app.perPage,
    }),
  },
  methods: {
    handleInputChange() {
      if (this.currentPage !== this.pageInput) {
        this.onPageChange(this.pageInput);
      }
    },
    handlePerPageChange(newValue) {
      this.$store.dispatch('app/setPerPage', newValue);
    },
  },
  watch: {
    currentPage(newValue) {
      this.pageInput = newValue;
    },
  },
};
</script>

<style scoped>
.page{
  width: 45px;
}
</style>
