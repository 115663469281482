<template>
  <div class="form-group">
    <label :for="id">{{ label + (rules ? '*' : '') || id }}</label>
    <ValidationProvider :name="label" :rules="rules" v-slot="{ errors }">
      <textarea v-if="type == 'textarea'" :id="id" :value="value" @input="$emit('input', $event.target.value)" :class="{ 'form-control': true, 'is-invalid': hasErrors }" />
      <input
        v-if="(type == 'input' || type == 'date') && mask == null"
        :type="type"
        :id="id"
        :value="value"
        :maxlength="maxlength"
        @input="$emit('input', makeCapital($event.target.value))"
        @change="$emit('change', makeCapital($event.target.value))"
        @keyup.enter="$emit('keyup', arguments[0])"
        @keydown.down="$emit('keydown', arguments[0])"
        @keydown.up="$emit('keydown', arguments[0])"
        @keydown.enter="$emit('keydown', arguments[0])"
        @keydown.esc="$emit('keydown', arguments[0])"
        @blur="$emit('blur')"
        :class="{ 'form-control': true, 'is-invalid': errors[0], datepick: isDate }"
        autocomplete="off"
        :required="required"
      />
      <masked-input
        v-if="mask"
        :type="type"
        :id="id"
        v-model="maskedVal"
        :value="value"
        @input="$emit('input', pureVal)"
        @change="$emit('change', $event.target.value)"
        @keyup.enter="$emit('keyup', arguments[0])"
        :class="{ 'form-control': true, 'is-invalid': errors[0] }"
        :mask="getMask"
        :placeholder="getPlaceholder"
        :guide="false"
        :required="required"
      >
      </masked-input>
      <span>{{ errors[0] }}</span>
    </ValidationProvider>
    <span v-if="hasErrors" class="invalid-feedback">
      <div v-for="error in errors" :key="error">{{ error }}</div>
    </span>
  </div>
</template>
<script>
import MaskedInput from 'vue-text-mask';
import { conformToMask } from 'vue-text-mask';
import emailMasks from 'text-mask-addons/dist/emailMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  components: {
    MaskedInput,
  },
  data() {
    return {
      emailMask: emailMasks,
      maskedVal: null,
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'input',
      validator: value => ['input', 'textarea', 'date'].includes(value),
    },
    mask: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: String,
      default: null,
    },
    textTransform: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    value(newValue) {
      if (newValue != null && newValue != '') {
        this.maskedVal = this.toMask(newValue);
      }
    },
  },
  methods: {
    makeCapital(str) {
      if (this.textTransform == 'capitalize') {
        return str.replace(/(^|\s)\S/g, l => l.toUpperCase());
      }
      if (this.textTransform == 'uc') {
        return str.toUpperCase();
      }
      return str;
    },
    toMask(val) {
      if (this.mask == 'price') {
        var rawPrice = val;
        var formattedPrice = Number(rawPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        });
        return formattedPrice;
      }
      if (this.mask == 'email') {
        return val;
      }
      if (this.mask != 'email' && this.mask != null) {
        var conformed = conformToMask(val, this.getMask, { guide: false });
        return conformed.conformedValue;
      }
      return val;
    },
  },
  computed: {
    isDate() {
      if (this.type == 'date') {
        return true;
      }
      return false;
    },
    pureVal() {
      if (this.mask == 'email') {
        return this.maskedVal;
      }
      return this.maskedVal.replace(/\$/g, '').replace(/,/g, '').replace(/\(/g, '').replace(/\)/g, '').replace('-', '').replace(/_/g, '').replace(/ /g, '');
    },
    hasErrors() {
      return !!this.errors.length;
    },
    getPlaceholder() {
      if (this.mask == 'email') {
        return 'info@example.com';
      }
      if (this.mask == 'price') {
        return '$1,000';
      }
      if (this.mask == 'phone') {
        return '(555) 555-5555';
      }
      if (this.mask == 'cc') {
        return '5424 0000 0000 0015';
      }
      if (this.mask == 'exp') {
        return '07/24';
      }
      if (this.mask == 'cvv') {
        return '123';
      }
      return '';
    },
    getMask() {
      if (this.mask == 'email') {
        return this.emailMask;
      }
      if (this.mask == 'price') {
        return createNumberMask({ prefix: '$', allowDecimal: true });
      }
      if (this.mask == 'phone') {
        return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      }
      if (this.mask == 'cc') {
        return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
      }
      if (this.mask == 'exp') {
        return [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/];
      }
      if (this.mask == 'cvv') {
        return [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
      }
      return false;
    },
  },
  async mounted() {
    if (this.value) {
      if (this.mask == 'phone' && String(this.value).includes('+1')) {
        this.maskedVal = this.toMask(String(this.value).replace('+1', ''));
      } else {
        this.maskedVal = this.toMask(this.value);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
textarea {
  height: 120px;
}

.datepick {
  position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
