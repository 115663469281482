import { ApiClient } from '@/api/ApiClient';
import Vue from 'vue';

const state = {
  items: [],
  model: {},
  isLoading: false,
  hasNextPage: false,
  sort: null,
  page: 1,
  perPage: 20,
  search: null,
  carrierNotes: {},
};

const getters = {};

const actions = {
  async fetch({ state, commit, rootState }, params = {}) {
    commit('setLoading', true);
    params.perPage = rootState.app.perPage;
    params.page = state.page;
    params.sort = state.sort;
    params.search = state.search;
    const response = await ApiClient.carriers.get({ params });
    commit('setItems', response.data);
    commit('setLoading', false);
  },
  async create({ commit }, { carrierId, data }) {
    const response = await ApiClient.carriers.post({ body: data });
    if (response.status === 200) {
      commit('updateCarrier', { carrierId, attributes: response.data.data });
    }
    return response;
  },
  async update({ commit }, { carrierId, updatedAttributes }) {
    const response = await ApiClient.carriers.put({ id: carrierId, body: updatedAttributes });
    if (response.status === 200) {
      commit('updateCarrier', { carrierId, attributes: response.data.data });
    }
    return response;
  },
  async remove(_, { carrierId }) {
    const response = await ApiClient.carriers.delete({ id: carrierId });
    if (response.status === 200) {
      //commit('removeCarrier', { carrierId });
    }
    return response;
  },
  async fetchModel({ commit }, { carrierId }) {
    const response = await ApiClient.carriers.get({ id: carrierId });
    if (response.status === 200) {
      commit('setCarrier', { model: response.data.data });
    }
    return response;
  },
};

const mutations = {
  setItems(state, carriers) {
    const { data, nextPageUrl } = carriers;
    state.items = data;
    state.hasNextPage = !!nextPageUrl;
  },
  setLoading(state, value) {
    state.isLoading = value;
  },
  setCarrier(state, { model }) {
    Object.assign(state.model, model);
  },
  updateCarrier(state, { carrierId, attributes }) {
    const matchingCarrier = state.items.find(lead => lead.id === carrierId);
    if (matchingCarrier) Object.assign(matchingCarrier, attributes);
    Object.assign(state.model, attributes);
  },
  setCarrierNotes(state, { carrierId, notes }) {
    Vue.set(state.carrierNotes, carrierId, notes);
  },
  unsetCarrierNotes(state, { carrierId }) {
    Vue.delete(state.carrierNotes, carrierId);
  },
  updateCarrerNote(state, note) {
    if(!state.carrierNotes[note.carrierId]) return;
    const matchingNote = state.carrierNotes[note.carrierId].find(x => x.id === note.id);
    if (matchingNote) Object.assign(matchingNote, note);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
