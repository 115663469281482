<template>
  <div>
    <div class="position-relative mb-3">
    <div class="grid--three-column">
      <div>
        <ValidatedInput
          rules="required"
          :label="label + ' City'"
          id="delivery-city"
          text-transform="capitalize"
          :value="formFields.city"
          :errors="errorResolver('city')"
          @input="newValue => handleInputChange({ field: 'city', newValue })"
          class="mb-0"
        />
      </div>
      <ValidatedInput
        rules="required"
        :label="label + ' State'"
        id="delivery-state"
        :value="formFields.state"
        :errors="errorResolver('state')"
        @input="newValue => handleInputChange({ field: 'state', newValue })"
        class="mb-0"
      />
      <ValidatedInput
        rules="required"
        :label="label + ' Zip'"
        id="delivery-zip"
        :value="formFields.zip"
        :errors="errorResolver('zip')"
        @input="newValue => handleInputChange({ field: 'zip', newValue })"
        class="mb-0"
      />
    </div>

    <div class="card p-3 mb-3 position-absolute w-100" v-if="show">
      <h6 class="mb-2">Suggestions</h6>
      <LoadingIndicator v-if="loading" centered />
      <div v-if="!loading">
        <ul class="list-group">
          <li class="list-group-item" v-if="suggestions.length">
            <b>Suggestions</b>
          </li>
          <li class="list-group-item"  v-for="item in suggestions"  :key="item.zip + item.city" @click="handleSuggestion(item)" style="cursor: pointer;">
            {{ item.city }}, {{ item.state }} {{ item.zip }}
          </li>
          <li v-if="!suggestions.length">>No Suggestions Available</li>
        </ul>
      </div>
    </div>

    </div>

    <ValidatedInput
      label="Address line 1"
      id="address-1"
      text-transform="capitalize"
      :value="formFields.address"
      :errors="errorResolver('address')"
      @input="newValue => handleInputChange({ field: 'address', newValue })"
    />
    <ValidatedInput
      label="Address line 2"
      id="address-2"
      :value="formFields.address2"
      :errors="errorResolver('address2')"
      @input="newValue => handleInputChange({ field: 'address2', newValue })"
    />
    <ValidatedSelect
      label="Location type"
      id="locationType"
      :empty-option="true"
      :value="formFields.locationTypeId"
      :options="locationTypes"
      @input="newValue => handleInputChange({ field: 'locationTypeId', newValue })"
      :errors="errorResolver['locationTypeId']"
    />
  </div>
</template>

<script>
import ValidatedInput from '@/components/shared/ValidatedInput';
import ValidatedSelect from '@/components/shared/ValidatedSelect';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import { debounce } from 'debounce';
import { ApiClient } from '@/api/ApiClient';
import locationTypesMixin from '@/mixins/locationTypes';

const searchSuggestions = debounce(async (searchTerm, callback) => {
  callback(await ApiClient.locations.get({ params: { cdq: searchTerm } }));
}, 200);

export default {
  components: {
    ValidatedInput,
    ValidatedSelect,
    LoadingIndicator,
  },
  mixins: [locationTypesMixin],
  props: {
    formFields: {
      type: Object,
      default: () => ({}),
    },
    errorResolver: {
      type: Function,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      suggestions: [],
      suggestionsCount: 10,
      loading: false,
      show: false,
    };
  },
  methods: {
    handleInputChange({ field, newValue }) {
      const newFormFields = Object.assign({}, this.formFields);
      newFormFields[field] = newValue;
      this.$emit('input', newFormFields);

      if (field == 'city' || field == 'zip') this.searhCityZipState(((newFormFields.zip || '') + ',' + (newFormFields.city || '')).trim());
    },
    handleSuggestion(item) {
      const newFormFields = Object.assign({}, this.formFields, item);
      this.$emit('input', newFormFields);
      this.suggestions = [];
      this.show = false;
    },
    async searhCityZipState(searchTerm) {
      //console.log(searchTerm);
      if (searchTerm.length > 2) {
        this.show = true;
        this.loading = true;
        searchSuggestions(searchTerm, apiResponse => {
          this.suggestions = apiResponse.data.data.slice(0, this.suggestionsCount);
          this.loading = false;
        });
      } else {
        this.suggestions = [];
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid--three-column {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}
</style>
