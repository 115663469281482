<template>
  <div class="image-container">
    <!-- DELETE BUTTON ON HOVER -->
    <div v-if="edit" class="deleteButton">
      <button @click="$emit('delete')" type="button" class="btn btn-light btn-sm">
        <i class="fas fa-trash-alt"></i>
      </button>
    </div>
    <!-- IMAGE -->
    <img :src="url" alt="Image" class="preview-img"/>
  </div>
</template>

<script>
export default {
  name: 'ImageCard',
  props: {
    url: {
      type: String,
      default: ''
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>
.image-container {
  width: 122px;
  height: 90px;
  position: relative;
  align-items: center;
  display: flex;
}

.deleteButton {
  position: absolute;
  bottom: 5px;
  right: 10px;
  border-radius: 4px;
  background-color: white;
  display: none;
}

.image-container:hover .deleteButton {
  display: block;
}

.preview-img {
  width: 122px;
  height: 90px;
  object-fit: cover;
  border-radius: 4px;
  text-align: center;
  align-content: center;
  background: white;
}
</style>
