import { render, staticRenderFns } from "./Carrier.vue?vue&type=template&id=91c6d102&scoped=true&"
import script from "./Carrier.vue?vue&type=script&lang=js&"
export * from "./Carrier.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91c6d102",
  null
  
)

export default component.exports