<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Quotes</h1>
      <div class="w-25">
        <UserDropdown @select="updateUserFilter" :value="userFilter" />
      </div>
    </div>
    <ul class="nav nav-tabs">
      <li v-for="status in statuses" :key="status.id" class="nav-item">
        <router-link :class="{ 'nav-link': true, active: isActive(status) && !$route.query.hasFollowup }" :to="{ name: 'QuotesWithStatus', params: { status: status.id } }">
          {{ status.title }}{{ statusStats[status.id] }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :class="{ 'nav-link': true, active: $route.name == 'QuoteList' && $route.query.hasFollowup }" :to="{ name: 'QuoteList', query: { hasFollowup: 1 } }">
          Follow-Ups
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :class="{ 'nav-link': true, active: showArchived }" :to="{ name: 'QuotesArchived' }"> Archived </router-link>
      </li>
    </ul>
    <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div class="d-flex align-items-center">
          <div class="mr-3">
            <SearchInput v-model="search" @input="startSearch" placeholder="Search..."/>
          </div>
          <b-form-checkbox class="mb-0" v-if="search" v-model="searchArchived" @input="startSearch" :unchecked-value="0" :value="1">Include archived</b-form-checkbox>
        </div>
        <div class="d-flex">
          <b-button variant="outline-secondary" size="sm" class="mx-2" @click="showFilters = !showFilters"><span class="fas fa-filter"></span></b-button>
        </div>
        <div class="d-flex" v-if="$route.query.hasFollowup">
          <div>
            Filter by Date:
            <b-select class="custom-select mr-3" size="sm"  v-model="followupFilter" @input="setFollowupFilter" :options="followupFilterOptions" />
          </div>
        </div>
      </div>

      <div v-if="showFilters" class="mb-3 p-3">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label>Created After</label>
              <input class="form-control" v-model="createdAfterFilter" type="date" />
            </div>
            <div class="mb-3">
              <label>Created Before</label>
              <input class="form-control" v-model="createdBeforeFilter" type="date" />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label>Move Date After</label>
              <input class="form-control" v-model="moveDateAfterFilter" type="date" />
            </div>
            <div class="mb-3">
              <label>Move Date Before</label>
              <input class="form-control" v-model="moveDateBeforeFilter" type="date" />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label>State From</label>
              <b-form-select v-model="stateFromFilter" :options="stateOptions"></b-form-select>
            </div>
            <div class="mb-3">
              <label>State To</label>
              <b-form-select v-model="stateToFilter" :options="stateOptions"></b-form-select>
            </div>
          </div>
        </div>
        <b-button variant="secondary" size="sm" class="mx-2" @click="clearFilters">Clear</b-button>
        <b-button variant="secondary" size="sm" class="mx-2" @click="startFilter">Filter</b-button>
      </div>

      <b-nav tabs class="tabs-alt" v-if="searchStats">
        <b-nav-item :to="{ name: 'LeadList', query: { search: search, searchArchived: searchArchived } }"
          >Leads <b-badge variant="outline-success">{{ searchStats.countLeads }}</b-badge></b-nav-item
        >
        <b-nav-item active
          >Quotes <b-badge variant="primary">{{ searchStats.countQuotes }}</b-badge></b-nav-item
        >
        <b-nav-item :to="{ name: 'OrderList', query: { search: search, searchArchived: searchArchived } }"
          >Orders <b-badge variant="outline-success">{{ searchStats.countOrders }}</b-badge></b-nav-item
        >
      </b-nav>
      <QuoteListTable :loading="loading" :items="quotes" route-for-individual-item="QuoteDetails" ref="leadlikeTable" />
      <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="updatePageUrlParam" :is-disabled="loading" />

      <b-form inline class="my-4 d-flex justify-content-end">
        <b-form-select v-model="selectedBulkAction" :options="bulkActions" size="sm"></b-form-select>
        <b-button variant="outline-primary" size="sm" class="mx-2" @click="bulkAction">Update orders</b-button>
      </b-form>

      <b-form inline class="my-4 d-flex justify-content-end" v-if="tokenCan('orders:assign')">
        <UserDropdown @select="setBulkUser" :value="bulkUser" :show-empty="false" class="w-25" />
        <b-button variant="outline-primary" size="sm" class="mx-2" @click="bulkAssign">Reassign agent</b-button>
      </b-form>
    </div>
    </div>
  </div>
</template>

<script>
import UserDropdown from '@/components/shared/UserDropdown';
import { ApiClient } from '@/api/ApiClient';
import { stringToSnakeCase } from '@/api/caseHelpers';
import QuoteListTable from '@/components/pages/quotes/QuoteListTable.vue';
import pageLinksMixin from '@/mixins/pageLinks';
import statesMixin from '@/mixins/states';
import { debounce } from 'debounce';
import permissionsMixin from '/src/mixins/permissions';
import SearchInput from '@/components/shared/SearchInput.vue'

const updateUrlParamDebounced = debounce(function (router, search) {
  router.push({ query: search });
}, 800);

const quoteStatuses = [
  { title: 'Quotes', id: 2 },
  //  { title: 'Follow-Ups', id: 3 },
  { title: 'Hold', id: 4 },
];

export default {
  name: 'QuoteList',
  components: {
    QuoteListTable,
    UserDropdown,
    SearchInput
  },
  mixins: [pageLinksMixin, permissionsMixin,statesMixin],
  props: {
    showArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statuses: quoteStatuses,
      quotes: [],
      leadStats: [],
      loading: false,
      search: '',
      searchArchived: false,
      searchStats: null,
      bulkActions: [
        { value: null, text: 'Select action' },
        // { value: 'cancel', text: 'Cancel' },
      ],
      selectedBulkAction: null,
      bulkUser: null,
      followupFilter: '1',
      followupFilterOptions: [
        { text: 'All', value: '1' },
        { text: 'Future', value: '2' },
        { text: 'Today', value: '3' },
        { text: 'Expired', value: '4' },
      ],
      showFilters : false,
      moveDateBeforeFilter: null,
      moveDateAfterFilter: null,
      createdBeforeFilter: null,
      createdAfterFilter: null,
      stateFromFilter: null,
      stateToFilter: null,
    };
  },
  computed: {
    selectedStatus() {
      const { status } = this.$route.params;
      if (status) {
        return this.statuses.find(s => s.id == status);
      } else {
        return this.statuses[0];
      }
    },
    hasNextPage() {
      return this.quotes.length === this.perPage;
    },
    userFilter() {
      return this.$store.state.leads.userFilter;
    },
    sortOrder() {
      const { sort } = this.$route.query;
      return sort;
    },
    statusStats() {
      let r = {};
      for (let status of this.statuses) {
        let found = this.leadStats.find(x => x.leadStatusId == status.id);
        r[status.id] = found && found.count ? ' (' + found.count + ')' : '';
      }
      return r;
    },
    stateOptions() {
      return this.states.map(x =>{return {text: x.label, value: x.value}});
    },
  },
  methods: {
    async quotesApiRequest() {
      let opts = {
        page: this.currentPage,
        per_page: this.perPage,
        leadTypeId: 2,
        search: this.search,
        searchArchived: this.searchArchived,
        sort: this.sortOrder,
        userId: this.userFilter,
        moveDateBefore: this.moveDateBeforeFilter, 
        moveDateAfter: this.moveDateAfterFilter,
        createdBefore: this.createdBeforeFilter,
        createdAfter: this.createdAfterFilter,
        stateFrom: this.stateFromFilter,
        stateTo: this.stateToFilter,
      };

      if (this.$route.query.leadList) {
        opts.leadList = this.$route.query.leadList;
        opts.searchArchived = 1;
      }
      opts.leadStatusId = this.showArchived || this.$route.query.leadList ? null : this.selectedStatus.id;

      if (this.showArchived) opts.isArchived = 1;
      if (this.$route.query.hasFollowup !== undefined) {
        opts.hasFollowup = this.followupFilter;
        opts.sort = this.sortOrder ? this.sortOrder : 'followup_at';
      }

      opts.sort = stringToSnakeCase(opts.sort);

      return ApiClient.leads.get({ params: opts });
    },
    async fetchLeadStats() {
      const response = await ApiClient.leadStats.get({
        params: {
          leadTypeId: 2,
          userId: this.userFilter,
        },
      });
      this.leadStats = response.data.data;
    },
    async fetch() {
      this.loading = true;
      const response = await this.quotesApiRequest();
      this.quotes = response.data.data;
      this.searchStats = response.data.search;
      this.loading = false;
    },
    startSearch() {
      updateUrlParamDebounced(this.$router, { search: this.search, searchArchived: this.searchArchived });
    },
    startFilter() {
      let obj = { 
        moveDateBefore: this.moveDateBeforeFilter, 
        moveDateAfter: this.moveDateAfterFilter,
        createdBefore: this.createdBeforeFilter,
        createdAfter: this.createdAfterFilter,
        stateFrom: this.stateFromFilter,
        stateTo: this.stateToFilter,
      };
      Object.keys(obj).forEach(key => (obj[key] === undefined || obj[key] === null) && delete obj[key]);
      this.$router.push({ query: obj });
    },
    clearFilters() {
      this.moveDateBeforeFilter= null;
      this.moveDateAfterFilter= null;
      this.createdBeforeFilter= null;
      this.createdAfterFilter= null;
      this.stateFromFilter= null;
      this.stateToFilter= null;
      this.startFilter();
    },
    updateUserFilter(userId) {
      if (this.userFilter != userId) {
        this.$store.commit('leads/setUserFilter', userId);
      }
    },
    setFollowupFilter(){
      let query = Object.assign({}, this.$route.query, { hasFollowup : this.followupFilter });
      this.$router.push({ query });
    },
    async bulkAction() {
      let body = {};
      body.action = this.selectedBulkAction;
      body.rows = this.$refs.leadlikeTable.selectedRows;
      if (!body.rows.action || !body.rows.length) return;

      this.fetch();
    },
    async bulkAssign() {
      let body = {};
      body.user_id = this.bulkUser;
      body.rows = this.$refs.leadlikeTable.selectedRows;
      if (!body.rows.length || !body.user_id) return;

      await ApiClient.bulkActions.assign({ body });
      this.$refs.leadlikeTable.selectedRows = [];
      this.$refs.leadlikeTable.rowToggle = false;
      this.fetch();
    },
    setBulkUser(userId) {
      this.bulkUser = userId;
    },
    isActive(status) {
      return !this.showArchived && status.id == this.selectedStatus.id;
    },

  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        const { search, searchArchived, hasFollowup, moveDateAfter, moveDateBefore, createdAfter, createdBefore, stateFrom, stateTo } = this.$route.query;
        this.search = search;
        this.searchArchived = searchArchived ? 1 : 0;
        this.followupFilter = hasFollowup || '1';
        this.moveDateAfterFilter = moveDateAfter || null;
        this.moveDateBeforeFilter = moveDateBefore || null;
        this.createdAfterFilter = createdAfter || null;
        this.createdBeforeFilter = createdBefore || null;
        this.stateFromFilter = stateFrom || null;
        this.stateToFilter = stateTo || null;

        if(this.$route.name == "QuotesArchived") {
          this.searchArchived = 1;
        }
        this.fetch();
        this.fetchLeadStats();
      },
    },
/*
    selectedStatus() {
      this.fetch();
      this.fetchLeadStats();
    },
*/
    userFilter() {
      this.fetch();
      this.fetchLeadStats();
    },
  },
  mounted() {
    const { search, searchArchived, hasFollowup, moveDateAfter, moveDateBefore, createdAfter, createdBefore, stateFrom, stateTo } = this.$route.query;
    this.search = search;
    this.searchArchived = searchArchived ? 1 : 0;
    this.followupFilter = hasFollowup || '1';
    this.moveDateAfterFilter = moveDateAfter || null;
    this.moveDateBeforeFilter = moveDateBefore || null;
    this.createdAfterFilter = createdAfter || null;
    this.createdBeforeFilter = createdBefore || null;
    this.stateFromFilter = stateFrom || null;
    this.stateToFilter = stateTo || null;

    // $route watcher already loads these
    //this.fetch();
    //this.fetchLeadStats();
  },
};
</script>

<style>
.nav-item--spacer {
  width: 8px;
}

.search-wrapper {
  max-width: 300px;
}
</style>
