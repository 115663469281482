<template>
  <tbody>
    <tr>
      <td  @click="$emit('click')" class="cursor-pointer">
        <i v-if="isSelected" class="fas fa-chevron-up"></i>
        <i v-else class="fas fa-chevron-down"></i>
      </td>
      <td @click="$emit('click')" class="cursor-pointer">{{ attachment.name }}</td>
      <td @click="$emit('click')" class="cursor-pointer">{{ attachment.filename }}</td>
      <td @click="$emit('click')" class="cursor-pointer">{{ formatFileSize(attachment.filesize) }}</td>
      <td @click="$emit('click')" class="cursor-pointer">{{ attachment.user ? attachment.user.name : 'System' }}</td>
      <td @click="$emit('click')" class="cursor-pointer">{{ attachment.createdAt | formatDateTime }}</td>
      <td>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-outline-primary btn-sm" @click="fetchAttachment()" >
            <i class="fas fa-download"></i>
          </button>
          <button class="btn btn-outline-danger btn-sm" @click="deleteAttachment()">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </td>
    </tr>
    <tr :class="{'d-none': !isSelected }">
      <td colspan="7">
        <img v-if="isImage(attachment.filename)" :src="attachment.publicUrl" class="img" />
        <object v-if="isPdf(attachment.filename)" :data="attachment.publicUrl" type="application/pdf" width="100%" height="500px">
          <p>Unable to display PDF file. <a :href="attachment.publicUrl">Download</a> instead.</p>
        </object>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'Attachment',
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isImage(filename) {
      let ext = filename.substring(filename.lastIndexOf('.') + 1, filename.length).toLowerCase() || filename.toLowerCase();
      if (['png', 'jpg', 'jpeg'].includes(ext)) return true;
      return false;
    },
    isPdf(filename) {
      let ext = filename.substring(filename.lastIndexOf('.') + 1, filename.length).toLowerCase() || filename.toLowerCase();
      if (['pdf'].includes(ext)) return true;
      return false;
    },
    formatFileSize(x = 0, dec = 2) {
      if (x < 1000) return x + ' Bytes';
      x = ('0'.repeat(((x += '').length * 2) % 3) + x).match(/.{3}/g);
      return Number(x[0]) + '.' + x[1].substring(0, dec) + ' ' + '  kMGTPEZY'[x.length] + 'B';
    },
    deleteAttachment() {
      this.$emit('delete', this.attachment );
    },
    fetchAttachment() {
      this.$emit('download', this.attachment );
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  max-width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
</style>