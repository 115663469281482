<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <span class="header align-content-center">Dispatchers</span>
        <button class="btn btn-warning" :class="{ invisible: addNew }" @click="addNew = !addNew"><span class="fas fa-plus"></span> Add new</button>
      </div>
    </div>
    <div v-if="dispatchers.length" class="bg-light px-4 py-3">
      <SearchInput v-model="searchDispatchers" placeholder="Search" />
    </div>

    <div class="card-body">
      <div v-if="addNew" class="bg-light mb-3">
        <DispatcherForm @cancel="addNew = false" @save="val => addNewDispatcher(val)" />
      </div>
      <table v-if="dispatchers.length" class="table table-sm mb-0">
        <thead>
          <tr>
            <TableSortableHeader label="Name" field="name" class="border-0"/>
            <th scope="col" class="border-0">Number</th>
            <th scope="col" class="border-0"></th>
          </tr>
        </thead>
        <DispatcherRow
          v-for="dispatcher in filteredDispatchers"
          :key="dispatcher.id"
          :dispatcher="dispatcher"
          @delete="deleteDispatcher(dispatcher)"
          @update="val => updateDispatcher(dispatcher.id, val)"
        />
      </table>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import TableSortableHeader from '@/components/shared/TableSortableHeader';
import SearchInput from '@/components/shared/SearchInput.vue';
import DispatcherRow from './DispatcherRow.vue';
import DispatcherForm from './DispatcherForm.vue';

export default {
  name: 'DispatchersCard',
  components: { TableSortableHeader, DispatcherRow, SearchInput, DispatcherForm },
  props: {
    carrierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      addNew: false,
      searchDispatchers: '',
      dispatchers: [],
    };
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async addNewDispatcher(val) {
      await ApiClient.carriers.dispatchers.post({ carrierId: this.carrierId, body: val });
      this.addNew = false;
      this.fetch();
    },
    async fetch() {
      const apiResponse = await ApiClient.carriers.dispatchers.get({ carrierId: this.carrierId });
      this.dispatchers = apiResponse.data.data;
    },
    async deleteDispatcher(dispatcher) {
      await ApiClient.carriers.dispatchers.delete({ carrierId: this.carrierId, dispatcherId: dispatcher.id });
      this.dispatchers = this.dispatchers.filter(x => x.id != dispatcher.id);
    },
    async updateDispatcher(id, val) {
      const response = await ApiClient.carriers.dispatchers.put({ carrierId: this.carrierId, dispatcherId: id, body: val });
      const updatedDispatcher = response.data.data;
      const index = this.dispatchers.findIndex(dispatcher => dispatcher.id === id);
      if (index !== -1) {
        this.$set(this.dispatchers, index, updatedDispatcher);
      }
    },
  },
  computed: {
    filteredDispatchers() {
      return this.dispatchers.filter(dispatcher => dispatcher.name.toLowerCase().includes(this.searchDispatchers.toLowerCase()));
    },
  },
};
</script>

<style scoped>
.header {
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 0%;
}
</style>