<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1>Orders</h1>
      <div class="d-flex align-items-center">
        <b-dropdown id="dropdown-is" size="sm" ref="dropdown" variant="outline-secondary" class="px-3 mt-1">
          <template #button-content>
            Selected Statuses:
            <span>{{ selectedISs.length }}</span>
          </template>
          <b-dropdown-form>
            <div class="mb-3 custom-control custom-checkbox">
              <input @click="selectIS(1)" class="custom-control-input" id="filter-1" type="checkbox" :checked="getIS(1)" />
              <label for="filter-1" class="custom-control-label">
                <div>Very Urgent</div>
              </label>
            </div>
            <div class="mb-3 custom-control custom-checkbox">
              <input @click="selectIS(2)" class="custom-control-input" id="filter-2" type="checkbox" :checked="getIS(2)" />
              <label for="filter-2" class="custom-control-label">
                <div>Trucker Added</div>
              </label>
            </div>
            <div class="mb-3 custom-control custom-checkbox">
              <input @click="selectIS(3)" class="custom-control-input" id="filter-3" type="checkbox" :checked="getIS(3)" />
              <label for="filter-3" class="custom-control-label">
                <div>Good Pay</div>
              </label>
            </div>
            <div class="mb-3 custom-control custom-checkbox">
              <input @click="selectIS(4)" class="custom-control-input" id="filter-4" type="checkbox" :checked="getIS(4)" />
              <label for="filter-4" class="custom-control-label">
                <div>Last Day of Pickup</div>
              </label>
            </div>
            <div class="mb-3 custom-control custom-checkbox">
              <input @click="selectIS(5)" class="custom-control-input" id="filter-5" type="checkbox" :checked="getIS(5)" />
              <label for="filter-5" class="custom-control-label">
                <div>Delayed shipments</div>
              </label>
            </div>
            <div class="mb-3 custom-control custom-checkbox">
              <input @click="selectIS(6)" class="custom-control-input" id="filter-6" type="checkbox" :checked="getIS(6)" />
              <label for="filter-6" class="custom-control-label">
                <div>Pickup window started</div>
              </label>
            </div>
            <div class="mb-3 custom-control custom-checkbox">
              <input @click="selectIS(7)" class="custom-control-input" id="filter-7" type="checkbox" :checked="getIS(7)" />
              <label for="filter-7" class="custom-control-label">
                <div>VIP Customer</div>
              </label>
            </div>
          </b-dropdown-form>
        </b-dropdown>        
        <UserDropdown @select="updateUserFilter" :value="userFilter" />
      </div>
    </div>
    <ul class="nav nav-tabs">
      <li v-for="status in statuses" :key="status.id" class="nav-item">
        <router-link :class="{ 'nav-link': true, active: isActive(status) && !$route.query.hasFollowup }" :to="{ name: 'OrdersWithStatus', params: { status: status.id } }">
          {{ status.title }}{{ statusStats[status.id] }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :class="{ 'nav-link': true, active: $route.name == 'OrderList' && $route.query.hasFollowup }" :to="{ name: 'OrderList', query: { hasFollowup: 1 } }">
          Follow-Ups
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :class="{ 'nav-link': true, active: showArchived }" :to="{ name: 'OrdersArchived' }"> Archived </router-link>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="d-flex align-items-center">
          <div class="mr-3">
            <SearchInput v-model="search" @input="startSearch" placeholder="Search..."/>
          </div>
          <b-form-checkbox class="mb-0" v-if="search" v-model="searchArchived" @input="startSearch" :unchecked-value="0" :value="1">Include archived</b-form-checkbox>
        </div>
        <div class="d-flex" style="gap:8px;">
          <div class="d-flex align-items-center" style="gap:4px;">
            <div class="legend bg-success-light"></div>
            <div>Dealers</div>
          </div>
          <div class="d-flex align-items-center" style="gap:4px;">
            <div class="legend bg-red-light"></div>
            <div>Retail</div>
          </div>
        </div>
        <div class="d-flex">
          <b-button variant="outline-secondary" size="sm" class="mx-2" @click="showFilters = !showFilters"><span class="fas fa-filter"></span></b-button>
        </div>
        <div class="d-flex" v-if="$route.query.hasFollowup">
          <div>
            Filter by Date:
            <b-select class="custom-select mr-3" size="sm" v-model="followupFilter" @input="setFollowupFilter" :options="followupFilterOptions" />
          </div>
        </div>
      </div>

      <div v-if="showFilters" class="mb-3 p-3">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label>Created After</label>
              <input class="form-control" v-model="createdAfterFilter" type="date" />
            </div>
            <div class="mb-3">
              <label>Created Before</label>
              <input class="form-control" v-model="createdBeforeFilter" type="date" />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label>Move Date After</label>
              <input class="form-control" v-model="moveDateAfterFilter" type="date" />
            </div>
            <div class="mb-3">
              <label>Move Date Before</label>
              <input class="form-control" v-model="moveDateBeforeFilter" type="date" />
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <label>State From</label>
              <b-form-select v-model="stateFromFilter" :options="stateOptions"></b-form-select>
            </div>
            <div class="mb-3">
              <label>State To</label>
              <b-form-select v-model="stateToFilter" :options="stateOptions"></b-form-select>
            </div>
          </div>
        </div>
        <b-button variant="secondary" size="sm" class="mx-2" @click="clearFilters">Clear</b-button>
        <b-button variant="secondary" size="sm" class="mx-2" @click="startFilter">Filter</b-button>
      </div>

      <b-nav tabs class="tabs-alt" v-if="searchStats">
        <b-nav-item :to="{ name: 'LeadList', query: { search: search, searchArchived: searchArchived } }"
          >Leads <b-badge variant="outline-success">{{ searchStats.countLeads }}</b-badge></b-nav-item
        >
        <b-nav-item :to="{ name: 'QuoteList', query: { search: search, searchArchived: searchArchived } }"
          >Quotes <b-badge variant="outline-success">{{ searchStats.countQuotes }}</b-badge></b-nav-item
        >
        <b-nav-item active
          >Orders <b-badge variant="primary">{{ searchStats.countOrders }}</b-badge></b-nav-item
        >
      </b-nav>
      <LeadlikeTable :loading="loading" :items="orders" route-for-individual-item="OrderDetails" :tablestatus="selectedStatus.id" ref="leadlikeTable" />
      <PageLinks :current-page="currentPage" :has-next-page="hasNextPage" :on-page-change="updatePageUrlParam" :is-disabled="loading" />

      <b-form inline class="my-4 d-flex justify-content-end">
        <b-form-select v-model="selectedBulkAction" :options="bulkActions" size="sm"></b-form-select>
        <b-button variant="outline-primary" size="sm" class="mx-2" @click="bulkAction">Update orders</b-button>
      </b-form>

      <b-form inline class="my-4 d-flex justify-content-end" v-if="tokenCan('orders:assign')">
        <UserDropdown @select="setBulkUser" :value="bulkUser" :show-empty="false" class="w-25" />
        <b-button variant="outline-primary" size="sm" class="mx-2" @click="bulkAssign">Reassign agent</b-button>
      </b-form>
    </div>
    </div>
  </div>
</template>

<script>
import UserDropdown from '@/components/shared/UserDropdown';
import { ApiClient } from '@/api/ApiClient';
import LeadlikeTable from '@/components/shared/LeadlikeTable.vue';
import pageLinksMixin from '@/mixins/pageLinks';
import statesMixin from '@/mixins/states';
import { debounce } from 'debounce';
import permissionsMixin from '/src/mixins/permissions';
import SearchInput from '@/components/shared/SearchInput.vue';

const orderStatuses = [
  { title: 'Orders', id: 3 },
  { title: 'Posted CD', id: 6 },
  { title: 'Not signed', id: 7 },
  { title: 'Dispatched', id: 8 },
  { title: 'Picked-Up', id: 11 },
  { title: 'Delivered', id: 12 },
  { title: 'Issues', id: 9 },
  { title: 'Hold', id: 4 },
];

export default {
  components: {
    LeadlikeTable,
    UserDropdown,
    SearchInput
  },
  mixins: [pageLinksMixin, permissionsMixin,statesMixin],
  props: {
    showArchived: {
      type: Boolean,
      default: false,
    },
    leadIds: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      statuses: orderStatuses,
      orders: [],
      leadStats: [],
      loading: false,
      search: '',
      selectedISs: [],
      searchArchived: false,
      searchStats: null,
      bulkActions: [
        { value: null, text: 'Select action' },
        { value: 'raise0', text: 'Raise $0' },
        { value: 'raise25', text: 'Raise $25' },
        { value: 'raise50', text: 'Raise $50' },
        { value: 'raise100', text: 'Raise $100' },
        { value: 'raise150', text: 'Raise $150' },
        // { value: 'cancel', text: 'Cancel' },
      ],
      selectedBulkAction: null,
      bulkUser: null,
      followupFilter: '1',
      followupFilterOptions: [
        { text: 'All', value: '1' },
        { text: 'Future', value: '2' },
        { text: 'Today', value: '3' },
        { text: 'Expired', value: '4' },
      ],
      showFilters : false,
      moveDateBeforeFilter: null,
      moveDateAfterFilter: null,
      createdBeforeFilter: null,
      createdAfterFilter: null,
      stateFromFilter: null,
      stateToFilter: null,
    };
  },
  computed: {
    selectedStatus() {
      const { status } = this.$route.params;
      if (status) {
        return this.statuses.find(s => s.id == status);
      } else {
        if (this.showArchived) {
          return { title: 'Archived', id: 'archived' };
        }
        return this.statuses[0];
      }
    },
    hasNextPage() {
      return this.orders.length === this.perPage;
    },
    userFilter() {
      return this.$store.state.leads.userFilter;
    },
    sortOrder() {
      const { sort } = this.$route.query;
      return sort;
    },
    statusStats() {
      let r = {};
      for (let status of this.statuses) {
        let found = this.leadStats.find(x => x.leadStatusId == status.id);
        r[status.id] = found && found.count ? ' (' + found.count + ')' : '';
      }
      return r;
    },
    stateOptions() {
      return this.states.map(x =>{return {text: x.label, value: x.value}});
    },
  },
  methods: {
    selectIS(type) {
      if (this.selectedISs.includes(type)) {
        var index = this.selectedISs.indexOf(type);
        if (index !== -1) {
          this.selectedISs.splice(index, 1);
        }
      } else {
        this.selectedISs.push(type);
      }
      this.fetch();
    },
    getIS(type) {
      if (this.selectedISs.includes(type)) {
        return type;
      }
      return false;
    },
    async fetchLeadStats() {
      const response = await ApiClient.leadStats.get({
        params: {
          leadTypeId: 3,
          userId: this.userFilter,
        },
      });
      if (response.status == 200) {
        this.leadStats = response.data.data;
      }
    },
    async fetch() {
      this.loading = true;

      let opts = {
        page: this.currentPage,
        per_page: this.perPage,
        leadTypeId: 3,
        search: this.search,
        searchArchived: this.searchArchived,
        sort: this.sortOrder,
        userId: this.userFilter,
        moveDateBefore: this.moveDateBeforeFilter, 
        moveDateAfter: this.moveDateAfterFilter,
        createdBefore: this.createdBeforeFilter,
        createdAfter: this.createdAfterFilter,
        stateFrom: this.stateFromFilter,
        stateTo: this.stateToFilter,
      };

      if (this.selectedISs) {
        opts.tags = this.selectedISs.join(',');
      }

      if (this.$route.query.leadList) {
        opts.leadList = this.$route.query.leadList;
        opts.searchArchived = 1;
      }
      opts.leadStatusId = this.showArchived || this.$route.query.leadList ? null : this.selectedStatus.id;

      if (this.showArchived) opts.isArchived = 1;
      if (this.$route.query.hasFollowup !== undefined) {
        opts.hasFollowup = this.followupFilter;
        opts.sort = this.sortOrder ? this.sortOrder : 'followup_at';
      }

      const response = await ApiClient.leads.get({params: opts});
      this.loading = false;
      if (response.status == 200) {
        this.orders = response.data.data;
        this.searchStats = response.data.search;
      }
    },
    startSearch() {
      this.$router.push({ query: { search: this.search, searchArchived: this.searchArchived } });
    },
    startFilter() {
      let obj = { 
        moveDateBefore: this.moveDateBeforeFilter, 
        moveDateAfter: this.moveDateAfterFilter,
        createdBefore: this.createdBeforeFilter,
        createdAfter: this.createdAfterFilter,
        stateFrom: this.stateFromFilter,
        stateTo: this.stateToFilter,
      };
      Object.keys(obj).forEach(key => (obj[key] === undefined || obj[key] === null) && delete obj[key]);
      this.$router.push({ query: obj });
    },
    clearFilters() {
      this.moveDateBeforeFilter= null;
      this.moveDateAfterFilter= null;
      this.createdBeforeFilter= null;
      this.createdAfterFilter= null;
      this.stateFromFilter= null;
      this.stateToFilter= null;
      this.startFilter();
    },
    updateUserFilter(userId) {
      if (this.userFilter != userId) {
        this.$store.commit('leads/setUserFilter', userId);
      }
    },
    setFollowupFilter(){
      let query = Object.assign({}, this.$route.query, { hasFollowup : this.followupFilter });
      this.$router.push({ query });
    },
    bulkAction() {
      let body = {};
      body.action = this.selectedBulkAction;
      body.rows = this.$refs.leadlikeTable.selectedRows;
      if (!body.action || !body.rows.length) return;
      if (body.action == 'raise0' || body.action == 'raise25' || body.action == 'raise50' || body.action == 'raise100' || body.action == 'raise150') {
        this.raise(body.action, body.rows);
      }
      //this.fetch();
    },
    async raise(amount, leadRows) {
      const amounts = {
        raise0: 0,
        raise25: 25,
        raise50: 50,
        raise100: 100,
        raise150: 150,
      };
      var getAmount = amounts[amount];
      this.loading = true;
      const response = await ApiClient.bulkActions.raise({ body: { rows: leadRows, amount: getAmount } });
      this.loading = false;
      if (response.status == 200) {
        this.fetch();
      }
    },
    async bulkAssign() {
      let body = {};
      body.user_id = this.bulkUser;
      body.rows = this.$refs.leadlikeTable.selectedRows;
      if (!body.rows.length || !body.user_id) return;

      await ApiClient.bulkActions.assign({ body });
      this.$refs.leadlikeTable.selectedRows = [];
      this.$refs.leadlikeTable.rowToggle = false;
      this.fetch();
    },
    setBulkUser(userId) {
      this.bulkUser = userId;
    },
    isActive(status) {
      return !this.showArchived && status.id == this.selectedStatus.id;
    },
    debouncedFetchLeads : debounce(function () {
        this.fetch();
        this.fetchLeadStats();
    }, 800)
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        const { search, searchArchived, hasFollowup, moveDateAfter, moveDateBefore, createdAfter, createdBefore, stateFrom, stateTo } = this.$route.query;
        this.search = search;
        this.searchArchived = searchArchived ? 1 : 0;
        this.followupFilter = hasFollowup || '1';
        this.moveDateAfterFilter = moveDateAfter || null;
        this.moveDateBeforeFilter = moveDateBefore || null;
        this.createdAfterFilter = createdAfter || null;
        this.createdBeforeFilter = createdBefore || null;
        this.stateFromFilter = stateFrom || null;
        this.stateToFilter = stateTo || null;

        if(this.$route.name == "OrdersArchived") {
          this.searchArchived = 1;
        }
        this.debouncedFetchLeads();
      },
    },
/*
    selectedStatus() {
      this.fetch();
      this.fetchLeadStats();
    },
*/
    userFilter() {
      console.log('userFilter');
      this.fetch();
      this.fetchLeadStats();
    },
  },
  mounted() {
    const { search, searchArchived, hasFollowup, moveDateAfter, moveDateBefore, createdAfter, createdBefore, stateFrom, stateTo } = this.$route.query;
    this.search = search;
    this.searchArchived = searchArchived ? 1 : 0;
    this.followupFilter = hasFollowup || '1';
    this.moveDateAfterFilter = moveDateAfter || null;
    this.moveDateBeforeFilter = moveDateBefore || null;
    this.createdAfterFilter = createdAfter || null;
    this.createdBeforeFilter = createdBefore || null;
    this.stateFromFilter = stateFrom || null;
    this.stateToFilter = stateTo || null;

  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  padding-left: 15px;
  padding-right: 15px;
}

.search-wrapper {
  max-width: 300px;
}
.select-wrapper {
  max-width: 300px;
}
</style>

<style lang="scss">
#dropdown-is {
  .dropdown-menu {
    min-width: 250px;
    max-height: 400px;
    overflow: auto;
  }
}

.legend {
  width: 24px;
  height: 24px;
  padding: 3px;
  border-radius: 4px;
  border: 1px solid gray;
}
</style>