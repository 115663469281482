<template>
  <tr :class="{ deleting: this.deletingInProgress }">
    <td nowrap>{{ leadTypeLabel(note.leadTypeId) }}</td>
    <td nowrap>{{ note.createdAt | formatDateTime }}</td>
    <td class="w-100">
      <div class="d-flex align-items-center" v-if="editing" style="gap:8px;">
        <Mentionable
          :keys="['@']"
          :items="$store.state.users.mentionableUsers"
          offset="6"
        >
          <b-textarea v-model="noteInput" :rows="2" :max-rows="3" :disabled="updatingInProgress" class="form-control" />
          <template #no-result>
            {{ $store.state.users.loadingMentionableUsers ? 'Loading...' : 'No result' }}
          </template>
        </Mentionable>

        
        <button :disabled="updatingInProgress" class="btn btn-primary" @click="saveNote">Save</button>
      </div>
      <span class="note-text" v-else>{{ note.note }}</span>
    </td>
    <td nowrap>{{ note.user ? note.user.name : '' }}</td>
    <td>
      <div class="d-flex" v-if="canEdit">
        <i v-if="!editing && !note.deletedAt" @click="initiateEdit" class="action-button far fa-edit mr-2"></i>
        <i v-if="!deletingInProgress && !note.deletedAt" @click="deleteNote" class="action-button far fa-trash-alt"></i>
      </div>
    </td>
  </tr>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import permissionsMixin from '@/mixins/permissions';
import leadTypesMixin from '@/mixins/leadTypes';
import moment from 'moment';
import { Mentionable } from 'vue-mention'
import 'floating-vue/dist/style.css'

export default {
  mixins: [permissionsMixin, leadTypesMixin],
  props: {
    note: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Mentionable
  },
  data() {
    return {
      noteInput: '',
      deletingInProgress: false,
      updatingInProgress: false,
      editing: false,
    };
  },
  computed: {
    canEdit() {
      return !this.readOnly && ((this.note.user && this.isCurrentUser(this.note.user.id)) || this.tokenCan('admin'));
    },
  },
  methods: {
    moment: function () {
      return moment();
    },
    initiateEdit() {
      this.noteInput = this.note.note;
      this.editing = true;
    },
    async saveNote() {
      this.updatingInProgress = true;
      const response = await ApiClient.leads.notes.put({
        leadId: this.note.leadId,
        id: this.note.id,
        body: { note: this.noteInput },
      });
      this.updatingInProgress = false;
      if (response.status === 200) {
        this.editing = false;
        this.$store.commit('leads/updateLeadNote', response.data.data);
      }
    },
    async deleteNote() {
      this.deletingInProgress = true;
      const response = await ApiClient.leads.notes.delete({
        leadId: this.note.leadId,
        id: this.note.id,
      });
      this.deletingInProgress = false;
      if (response.status === 200) {
        this.$emit('noteDeleted', this.note.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  cursor: pointer;
}
.note-text {
  white-space: pre-line;
}
.deleting {
  opacity: 0.3;
}
</style>
<style lang="scss">
.mention-item {
  padding: 10px 10px;
  border-radius: 4px;
  min-width:100px;
}

.mention-selected {
  background: #efefef;
}

.mentionable {
  width:100%;
}
</style>