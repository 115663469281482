<template>
  <nav class="top-bar">
    <nav class="top-bar__inner">
      <!-- Brand demo (see demo.css) -->
      <a href="/" class="top-bar__brand">
        <img :src="require('@/assets/images/logo-only-dark.png')" class="top-bar__logo" alt="Nexus Nav Logo" />
        Nexus CRM
      </a>

      <div>
        <b-dropdown no-caret right variant=" bg-transparent" menu-class="notifications-dropdown">
          <template slot="button-content">
            <i class="ion ion-md-notifications-outline navbar-icon align-middle big-bell text-white"></i>
            <span class="badge badge-pill badge-primary indicator notif-count round text-white bg-primary" v-if="unreadNotifications.length">
              {{ unreadNotifications.length }}
            </span>
          </template>

          <div class="bg-primary text-center text-white font-weight-bold p-3">{{ unreadNotifications.length }} New Notifications</div>
          <b-list-group flush>
            <div ref="notifpanel" class="notif-panel">
              <b-list-group-item
                v-for="notification in notifications.slice(0, notifLength)"
                :key="notification.id"
                class="media d-flex align-items-center"
                :class="notification.isRead ? 'bg-light' : null"
                @click="() => handleGoNotification({ notification })"
              >
                <div :class="`ui-icon ui-icon big-icon ${notification.icon || 'fa fa-bell'} bg-secondary cursor border-0 text-white`"></div>
                <div class="media-body line-height-condenced ml-2 pl-1 cursor">
                  <div>
                    <h6 class="notif-title mb-0">
                      <b>{{ notification.title }}</b>
                    </h6>
                  </div>
                  <div class="text-body notif-body">{{ notification.body }}</div>
                </div>
              </b-list-group-item>
              <div class="text-center py-2">
                <button class="text-center btn btn-sm btn-primary btn-load" @click="loadMore()">Load More <i class="ml-1 fa fa-arrow-down"></i></button>
              </div>
            </div>
          </b-list-group>
          <b-link to="/notifications" class="d-block text-center p-2 my-1"> Show all notifications </b-link>
        </b-dropdown>
        <div class="d-inline">
          <button @click="toggleTheme" class="btn py-0">
            <i class="fas mt-1 text-white" :class="isDarkMode ? 'fa-moon' : 'fa-sun'" style="font-size: 22px"></i>
          </button>
        </div>
        <b-dropdown right variant="dark bg-transparent">
          <template slot="button-content">
            <span v-if="user" class="d-inline-flex flex-lg-row-reverse align-items-center align-middle text-white">
              <Avatar size="sm" :photo="user.photo" />
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ user.username }}</span>
            </span>
          </template>

          <b-dd-item to="/profile/details"> <i class="fas fa-user fa-fw text-secondary mr-1"></i> Profile </b-dd-item>
          <b-dd-divider />
          <b-dd-item @click="logout"> <i class="fas fa-sign-out-alt fa-fw text-danger mr-1"></i> Log Out </b-dd-item>
        </b-dropdown>
      </div>
    </nav>
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
  name: 'AppLayoutNavbar',
  data() {
    return {
      notifLength: 10,
      isDarkMode: localStorage.getItem('themeSettingsStyle') === 'dark',
    };
  },
  components: {
    Avatar,
  },
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      notifications: state => state.notifications.items,
    }),
    ...mapGetters({
      unreadNotifications: 'notifications/unread',
    }),
  },
  methods: {
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('themeSettingsStyle', this.isDarkMode ? 'dark' : 'light');
      window.location.reload(true);
    },
    async loadMore() {
      this.notifLength += 5;
    },
    async handleGoNotification({ notification }) {
      if (!notification.isRead) {
        await this.$store.dispatch('notifications/update', { id: notification.id, updatedAttributes: { isRead: 1 } });
      }
      if (notification.link) {
        if (notification.link.charAt(0) == '{') {
          this.$router.push(JSON.parse(notification.link));
          console.log(notification.link);
        } else {
          this.$router.push({ path: notification.link });
        }
      }
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  background-color: #333;
  color: #fafafa;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar__brand {
  display: flex;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 24px;
  padding-left: 16px;
}

.top-bar__logo {
  width: 32px;
  margin-right: 16px;
}

.top-bar__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
}

.top-bar__dropdown {
  background: none;
  border: none;
  box-shadow: none;
}

.notif-count {
  font-size: 12px !important;
  padding: 3px 1px !important;
  margin-top: -5px;
  margin-left: 3px;
  width: 20px;
  height: 20px;
}
.notif-panel {
  max-height: 500px;
  overflow: auto;
}
.btn-load {
  &:focus {
    box-shadow: unset !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
</style>
<style>
.notifications-dropdown {
  min-width: 28rem;
}
.cursor {
  cursor: pointer;
}
.notif-title {
  font-size: 17px;
}
.notif-body {
  font-size: 15px;
}
.big-icon {
  width: 36px;
  height: 36px;
  font-size: 19px;
  line-height: 36px !important;
}
.big-bell {
  font-size: 24px;
}
</style>