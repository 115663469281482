<template>
  <div class="row text-roboto">
    <div class="col">
      <DispatchersCard :carrierId="carrier.id" :public_url="public_url"/>
    </div>
    <div class="col">
      <DriversCard :carrierId="carrier.id"  :public_url="public_url"/>
    </div>
  </div>
</template>

<script>
import DispatchersCard from './DispatchersCard.vue';
import DriversCard from './DriversCard.vue';

export default {
  name: 'CarrierStaff',
  mounted(){
    const {publicUrl} = this
    this.public_url = publicUrl
  },
  props: {
    carrier: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { DispatchersCard, DriversCard },
  data() {
    return {
      public_url: ''
    };
  },
};
</script>

<style>
.text-roboto{
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0%;
}
</style>
