<template>
  <div class="tab-content">
    <div class="position-relative">
      <div v-if="loading" class="loading-overlay">
        <LoadingIndicator centered />
      </div>

      <table class="table">
        <thead class="table">
          <tr>
            <TableSortableHeader class="px-0 text-center" label="Role" field="role_id" />
            <TableSortableHeader class="px-0 text-center" label="Permission" field="permission_id" />
            <th scope="col">Description</th>
            <TableSortableHeader class="px-0 text-center" label="Created At" field="created_at" />
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody :class="{ 'table-loading': loading }">
          <tr v-for="item in rolePermissions" :key="item.id">
            <td>{{ item.role.name }}</td>
            <td>{{ item.permission?  item.permission.name : ' - '}}</td>
            <td>{{  item.permission? item.permission.description : ' - '}}</td>
            <td>{{ item.createdAt | formatDate }}</td>
            <td>

              <b-btn class="btn btn-secondary btn-sm" title="Delete" @click="() => handleDelete(item)">
                <i class="fas fa-trash-alt"></i> Remove
              </b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h5 class="card-header">Add new permission to role</h5>
    <div class="card-body">
      <b-form-group label="Role">
        <multiselect
          v-model="role"
          label="label"
          track-by="value"
          placeholder="Select role"
          :options="roles"
          :allow-empty="true"
          :multiple="false"
          :searchable="true"
          :internal-search="true"
          :clear-on-select="false"
          :close-on-select="true"
        >
          <span slot="noResult">No roles found</span>
        </multiselect>
      </b-form-group>
      
      <b-form-group label="Permission">
        <multiselect
          v-model="permission"
          label="label"
          track-by="value"
          placeholder="Select permission"
          :options="permissions"
          :allow-empty="true"
          :multiple="false"
          :searchable="true"
          :internal-search="true"
          :clear-on-select="false"
          :close-on-select="true"
        >
          <span slot="noResult">No permissions found</span>
        </multiselect>      
      </b-form-group>

      <button :disabled="saving" class="btn btn-primary" @click="() => handleSubmit()"  type="button">Save</button>
    </div>
  </div>
</template>

<script>
import { ApiClient } from '@/api/ApiClient';
import permissionsMixin from '/src/mixins/permissions';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import TableSortableHeader from '@/components/shared/TableSortableHeader';


export default {
  components: {
    LoadingIndicator,
    TableSortableHeader,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      rolePermissions: [],
      loading: false,
      saving: false,
      roles: [],
      permissions: [],
      role: null,
      permission: null,
    };
  },
  computed: {
    sortOrder() {
      const { sort } = this.$route.query;
      return sort;
    },
  },
  methods: {
    async fetchRoles() {
      const response = await ApiClient.roles.get({});
      if (response.status == 200) {
        this.roles = response.data.data.map(u => ({ value: u.id, label: u.name }));
      }
    },
    async fetchPermissions() {
      const response = await ApiClient.permissions.get({});
      if (response.status == 200) {
        this.permissions = response.data.data.map(u => ({ value: u.id, label: u.name + " | " + u.description }));
      }
    },
    async fetch() {
      if (this.loading) return;
      this.loading = true;

      let opts = {
        sort: this.sortOrder,
      };
      
      const response = await ApiClient.rolePermissions.get({params: opts});
      this.loading = false;
      if (response.status == 200) {
        this.rolePermissions = response.data.data;
      }
    },
    async handleDelete(item) {
      let text = "Remove " + (item.permission? item.permission.name : ' - ') + " from " + item.role.name + "?";
      if (confirm(text) == true) {
        await ApiClient.rolePermissions.delete({id: item.id});
        this.fetch();
      } 
    },
    async handleSubmit() {
      this.saving = true;
      await ApiClient.rolePermissions.post({body : {role_id: this.role.value, permission_id: this.permission.value}});
      this.saving = false;
      this.role = null;
      this.permission = null;
      this.fetch();
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.fetch();
      },
    },
  },

  async mounted() {
    this.fetchRoles();
    this.fetchPermissions();
  },

};
</script>

<style lang="scss" scoped>
.nav-link {
  padding-left: 15px;
  padding-right: 15px;
}

.search-wrapper {
  max-width: 300px;
}
.select-wrapper {
  max-width: 300px;
}

.table-loading {
  opacity: 0.5;
}
.loading-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
}
/*
tbody td {
  padding: 5px;
}
*/
td {
  vertical-align: middle;
}

.text-muted a {
  color: inherit;
}

</style>
